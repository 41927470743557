/* eslint-disable eqeqeq */
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import moment from "moment/moment";
import { Component } from "react";
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getAuthToken, getUserDetails } from "../../helpers/authentication";
import { Context } from '../../helpers/Context';
import { CustomFixedDecimals, GetRatingBand } from "../../helpers/global";
import EditEvaluation from "./Evaluation/EditEvaluations";
import RatingImpactTable from "./Evaluation/RatingImpactTable";

export default class RiskEvaluate extends Component {
  static contextType = Context;
  static parentObj = null;
  constructor(props) {
    super(props);

    RiskEvaluate.parentObj = this;
    this.state = {
      RiskId: this.props.RiskId,
      completedPerc: "50%",
      dateUpdated: "11/04/23",
      loading: true,
      editModal: false,
      riskEvaluateModal: false,
      DateAdded: new Date(),
      Percentage: 0,
      Note: '',
      editType: "",
      data: [],
      RiskImpactRatings: [],
      PotentialData: null,
      ActualData: null,
      TargetData: null,
      CurrentView: null,
    }

    this.OnEvaluationSave = this.OnEvaluationSave.bind(this);
    this.toggleRiskEvaluate = this.toggleRiskEvaluate.bind(this);
    this.editStatus = this.editStatus.bind(this);
  }


  toggleRiskEvaluate() {
    this.setState({
      riskEvaluateModal: !this.state.riskEvaluateModal
    });
  }

  componentDidMount() {
    const { currentView } = this.context;
    if (currentView) {
      this.setState({ CurrentView: currentView });
    }
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the parentState prop has changed
    const { currentView } = this.context;
    if (currentView !== prevState.CurrentView) {
      this.setState({ CurrentView: currentView });
    }
  }

  async loadData() {
    //console.log(this.props.OnClick)
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const User = getUserDetails();

    try {
      const response = await fetch(`api/sitestructures/${this.props.SiteId}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ Site: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskevaluatestatus/getbyriskid/${this.props.RiskId}/${User.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ RiskEvaluateStatusData: data, RiskEvaluateStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskevaluation/GetRiskEvaluations/${User.CompanyId}/${this.props.RiskId}/${User.UserRole}`, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ ...data, loading: false });
      }
    } catch (e) {
      console.error(e);
    }

    //setAuthToken(getAuthToken(), new Date());
  }

  async saveData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, riskEvaluateModal: false, showSuccess: false });

    //console.log(this.state.DateAdded)
    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: dataId, RiskId: this.props.RiskId, Percentage: this.state.Percentage, Note: this.state.Note, DateAdded: this.state.DateAdded, AddedBy: getUserDetails().Id, DateModified: new Date(), ModifiedBy: getUserDetails().Id, Status: 1, }
    //console.log(data)
    try {
      const response = await fetch('api/riskevaluatestatus', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.setState({ Percentage: 0, Note: "" });
        await this.props.Load();
        this.loadData();
        ToastUtility.show({
          title: 'Risk Evaluation Status', content: 'The risk evaluation status was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Evaluation Status', content: 'There was an error saving the risk evaluation status!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }

    //setAuthToken(getAuthToken(), new Date());
  }

  toggle() {
    this.setState({ editModal: !this.state.editModal });
  }

  editStatus = () => {
    this.setState({
      riskEvaluateModal: true,
      Note: '',
      Percentage: 0,
      PreviousPercentage: this.state.RiskEvaluateStatusData.Percentage,
      UserName: this.state.RiskEvaluateStatusData.UserName,
      PreviousDate: new Date(this.state.RiskEvaluateStatusData.DateModified).toDateString(),
      PreviousComment: this.state.RiskEvaluateStatusData.Note
    });
  }

  static ResetCurrentView() {
    const { setCurrentView } = this.context;
    setCurrentView(0);
  }

  renderEvaluationView(CurrentView) {
    //console.log(this.props.Consequences, this.props.RootCauses)
    switch (CurrentView) {
      case 0:
        return this.renderEvaluations();
      case 1:
        return (<div className="mt-3">
          <RatingImpactTable showPrevEvaluation={false} RatingImpactData={this.state.RiskImpactRatings} SiteId={this.props.SiteId} />
          <EditEvaluation EvaluationType="Potential" SubHeading={<small> (Ignores Current Controls) </small>} IsReadOnly={false} RootCauses={this.props.RootCauses} Consequences={this.props.Consequences} RiskId={this.state.RiskId} CurrentData={this.state.PotentialData} RatingImpactData={this.state.RiskImpactRatings} OnSave={this.OnEvaluationSave} Site={this.state.Site} />
        </div>)
      case 2:
        return (<div className="mt-3">
          <RatingImpactTable PrevEvaluationName={"Potential"} showPrevEvaluation={true} PrevEvaluationData={this.state.PotentialData} RatingImpactData={this.state.RiskImpactRatings} SiteId={this.props.SiteId} />
          <EditEvaluation EvaluationType="Actual" SubHeading={<small> (Considers Current Controls) </small>} IsReadOnly={false} RootCauses={this.props.RootCauses} Consequences={this.props.Consequences} Controls={this.props.Controls} RiskId={this.state.RiskId} ShowPrevEvaluation={true} PrevEvaluation={this.state.PotentialData} CurrentData={this.state.ActualData} RatingImpactData={this.state.RiskImpactRatings} OnSave={this.OnEvaluationSave} Site={this.state.Site} />
        </div>)
      case 3:
        return (<div className="mt-3">
          <RatingImpactTable PrevEvaluationName={"Actual"} showPrevEvaluation={true} PrevEvaluationData={this.state.ActualData} RatingImpactData={this.state.RiskImpactRatings} SiteId={this.props.SiteId} />
          <EditEvaluation EvaluationType="Target" SubHeading={<small></small>} IsReadOnly={false} RiskId={this.state.RiskId} CurrentData={this.state.TargetData} RatingImpactData={this.state.RiskImpactRatings} OnSave={this.OnEvaluationSave} Site={this.state.Site} />
        </div>);
      default:
        return this.renderEvaluations();
    }
  }

  async OnEvaluationSave() {
    const { setCurrentView } = this.context;
    setCurrentView(0);
    await this.props.Load()
    this.loadData();
    ToastUtility.show({
      title: 'Save Risk Evaluation', content: 'Risk evaluation was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
    });
  }

  renderEvaluations() {
    return (
      <div className="evaluation-container">
        {!this.state.loading && this.state.RiskEvaluateStatusData.Percentage > 0 ?

          <div className='d-flex'>
            <div className='empty-div'></div>
            <div className={this.state.RiskEvaluateStatusData.Percentage < 100 ? 'risk-update-section' : 'risk-update-section'}>
              <h3 className='risk-text col-md-4'>Evaluate the Risk: <em>{this.state.RiskEvaluateStatusData.Percentage}%  {this.state.RiskEvaluateStatusData.Percentage < 100 ? "Adequate" : "Completed"}</em></h3>
              <div className='col-md-5'>
                <small className=''>at {new Date(this.state.RiskEvaluateStatusData.DateModified).toProperDate()} <br /> {this.state.RiskEvaluateStatusData.UserName}</small>
                <button onClick={() => this.editStatus()} className='btn btn-info'><i className="far fa-edit me-2"></i>Update Status</button>
              </div>
            </div>
            <div className='info-div text-end'>
              <i className="fa-solid fa-circle-question" title={`What is this screen about?\n\n"Evaluate the Risk" is all about MEASUREMENT of this risk.\nIt involves doing three things...\n1. Determine your 'potential' risk level - the risk you face without the controls that you may have in place.\nDoing this is referred to as performing a 'Potential Risk Rating'.\n2. Determine your 'actual' risk level - the risk you actually face taking into account the controls that you already have in place.  \nDoing this is referred to as performing a 'Actual Risk Rating'.\n3. Determine your 'targeted' risk level - the risk level that you consider acceptable, together with a date that you wish to see this risk level attained.\nDoing this is referred to as performing a 'Target Risk Rating'.\n\nWhen all these three have been completed, then you have completed this phase.\n\nHow do I engage with this screen?\nClick on the respective 'Edit' buttons takes you to the screen which allows you to perform the respective risk rating. \n\nPlease keep completion status up to date...\nThe phase completion status is shown above the "Evaluate the Risk" button.\nPlease update this status periodically, in the bar at the bottom of this screen.  \n\nIn that way any user immediately sees the correct completion status at a glance.`}>
              </i>
            </div>
          </div> :
          <div className='d-flex'>
            <div className='empty-div'></div>
            <div className='risk-update-section'>
              <h3 className='risk-text col-md-12 text-center'>Evaluate the Risk: <em>Not Yet Started</em></h3>
              <div className='col-md-5'>
                <button onClick={() => this.editStatus()} className='btn btn-info'><i className="far fa-edit me-2"></i>Update Status</button>
              </div>
            </div>
            <div className='info-div text-end'>
              <i className="fa-solid fa-circle-question" title={`What is this screen about?\n\n"Evaluate the Risk" is all about MEASUREMENT of this risk.\nIt involves doing three things...\n1. Determine your 'potential' risk level - the risk you face without the controls that you may have in place.\nDoing this is referred to as performing a 'Potential Risk Rating'.\n2. Determine your 'actual' risk level - the risk you actually face taking into account the controls that you already have in place.  \nDoing this is referred to as performing a 'Actual Risk Rating'.\n3. Determine your 'targeted' risk level - the risk level that you consider acceptable, together with a date that you wish to see this risk level attained.\nDoing this is referred to as performing a 'Target Risk Rating'.\n\nWhen all these three have been completed, then you have completed this phase.\n\nHow do I engage with this screen?\nClick on the respective 'Edit' buttons takes you to the screen which allows you to perform the respective risk rating. \n\nPlease keep completion status up to date...\nThe phase completion status is shown above the "Evaluate the Risk" button.\nPlease update this status periodically, in the bar at the bottom of this screen.  \n\nIn that way any user immediately sees the correct completion status at a glance.`}>
              </i>
            </div>
          </div>}
        <div className='row mb-3'>
          <div className='col-md-4 mb-3 text-center'>
            {this.RenderRiskBlock(1, this.state)}
          </div>
          <div className='col-md-4 mb-3 text-center'>
            {this.RenderRiskBlock(2, this.state)}
          </div>
          <div className='col-md-4 mb-3  text-center'>
            {this.RenderRiskBlock(3, this.state)}
          </div>
        </div>

        <div className="row mt-3 align-items-center">
          <div className="col text-center align-self-center">
            <img src="/images/evolution-1.png" className="img-fluid my-auto" style={{ width: "100px" }} alt="Evolution 1" />
          </div>
          <div className="col text-center align-self-center">{this.getChangeStatus(1, this.state)}</div>
          <div className="col text-center">
            <img src="/images/evolution-2.png" className="img-fluid my-auto" style={{ width: "100px" }} alt="Evolution 2" />
          </div>
          <div className="col text-center align-self-center">{this.getChangeStatus(2, this.state)}</div>
          <div className="col mb-3 text-center">
            <img src="/images/evolution-3.png" className="img-fluid my-auto mb-2" style={{ width: "100px" }} alt="Evolution 3" />
            <br />
            <h6 className="fw-semibold">Targeted Completion Date</h6>
            {this.state.TargetData.TargetedCompletionDate ? <small className='border py-2 px-4'>{moment(this.state.TargetData.TargetedCompletionDate).format("DD/MM/YY")}</small> : <small className='border py-2 px-4'>Not Yet Set</small>}
          </div>
        </div>
      </div>)
  }

  getStyleByCompleteStatus(Status) {
    let style = {
      //border: "1px solid #000",
      padding: "3px"
    };
    return style;
  }

  getChangeStatus(Type) {
    let TotalDiff = 0;
    let OverallDiff = 0;
    let changePerc = 0;
    let potentialTotal = this.state.PotentialData.TotalLikelyImpact;
    let potentialOverall = this.state.PotentialData.OverallRating;
    let actualTotal = this.state.ActualData.TotalLikelyImpact;
    let actualOverall = this.state.ActualData.OverallRating;
    let targetTotal = this.state.TargetData.TotalLikelyImpact;
    let targetOverall = this.state.TargetData.OverallRating;

    switch (Type) {
      case 1:
        TotalDiff = Math.abs(potentialTotal - actualTotal);
        OverallDiff = Math.abs(potentialOverall - actualOverall);
        changePerc = Math.round((TotalDiff / potentialTotal) * 100);
        return this.renderChangesBlock(changePerc, OverallDiff, TotalDiff, <>reduction <br /> attained</>, <h6 className="fw-semibold">Controlled Gap</h6>, <p className="fw-bold">Addressed with EXISTING CONTROLS</p>)
      case 2:
        TotalDiff = Math.abs(actualTotal - targetTotal);
        OverallDiff = Math.abs(actualOverall - targetOverall);
        changePerc = Math.round((TotalDiff / actualTotal) * 100);
        changePerc = Math.min(changePerc, 100);
        return this.renderChangesBlock(changePerc, OverallDiff, TotalDiff, <>reduction <br /> required</>, <h6 className="fw-semibold">Uncontrolled Gap</h6>, <p className="fw-bold">Close with GAP-CLOSING ACTIONS</p>)
      default: return;
    }


  }

  renderChangesBlock(ChangePerc, Overall, TotalAmount, ReductionText, GapText, BottomText) {
    return (
      <div className="position-relative">
        {ChangePerc ? <div className="fw-semibold"> {ChangePerc}% {ReductionText} </div> : ""}
        <i className="fa fa-arrow-left position-absolute top-50 start-0"></i>
        <div className="alert-gap text-center p-1">
          {GapText}
          {TotalAmount > 0 ?
            <>
              <span> {CustomFixedDecimals(Overall)} </span><br />
              {getUserDetails().Currency + " " + TotalAmount.toFixed(2)}
            </> : ""}

        </div>
        <i className="fa fa-arrow-right position-absolute top-50 end-0"></i>
        <small>{BottomText}</small>
      </div>
    )
  }

  RenderRiskBlock(Type, Data) {
    let TypeData = null;
    let BlockHeading = "";
    let TopText = "";
    //let TotalFinance = 0;
    //console.log(Data.TargetData)
    //console.log(Data);
    //console.log(Type);
    switch (Type) {
      case 1:
        BlockHeading = "POTENTIAL";
        TypeData = Data.PotentialData;
        if (TypeData.Id > 0)
          TopText = <small style={this.getStyleByCompleteStatus(TypeData.CompleteStatus)}> {TypeData.CompleteStatus}% - {moment(TypeData.DateModified).format("DD/MM/YY")} </small>;
        else
          TopText = <small>NYS</small>;

        break;
      case 2:
        BlockHeading = "ACTUAL";
        TypeData = Data.ActualData;
        if (TypeData.Id > 0)
          TopText = <small>  {TypeData.CompleteStatus}% - {moment(TypeData.DateModified).format("DD/MM/YY")}</small>;
        else
          TopText = <small>NYS</small>;
        break;
      case 3:
        BlockHeading = "TARGET";
        TypeData = Data.TargetData;
        if (TypeData.Id > 0)
          TopText = <small>  {TypeData.CompleteStatus}% - {moment(TypeData.DateModified).format("DD/MM/YY")}</small>;
        else
          TopText = <small>NYS</small>;
        break;
      default:
        return;
    }

    //const Percentages = this.GetPercentages(TypeData.MetaData);
    const { setCurrentView } = this.context;
    return (
      <>
        {TopText}
        <Card className={"border-evaluation " + (BlockHeading.toLowerCase())}>
          <CardHeader className="text-center fw-bold">
            {BlockHeading}
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-4 text-center fw-bold">Risk Score</div>
              <div className="col-4 text-center fw-bold">Likely Impact</div>
              <div className="col-4 text-center fw-bold">Risk Level</div>
            </div>
            <hr className="py-1 my-1" />
            {
              TypeData && TypeData.MetaData.length > 0 &&
              TypeData.MetaData.map((item, idx) =>
                <div key={idx}>
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8 text-center align-self-center">{getUserDetails().Currency + " " + item.LikelyImpact.toFixed(2)}</div>
                    <div className="col-2 text-end align-self-center"></div>
                  </div>
                </div>
              )
            }
            <hr className="py-1 my-1" />
            <div className="row align-items-center">
              <div className="col-3 text-center fw-bold">
                {CustomFixedDecimals(TypeData.OverallRating)}
              </div>
              <div className="col-6 text-center fw-bold">
                {getUserDetails().Currency + " " + TypeData.TotalLikelyImpact}
              </div>
              <div className="col-3 text-center fw-bold">
                {TypeData.OverallRating > 0 ? GetRatingBand(TypeData.OverallRating, this.state.RiskImpactRatings, this.state.Site.ImmaterialityLevel, this.state.Site.CatastrophicLevel, this.state.Site.NumOfLevels, this.state.Site.TypeId, TypeData.TotalLikelyImpact) : 0}
              </div>
              <div className="col text-center">
                <button type="button" onClick={() => setCurrentView(Type)} className="btn btn-info"><i className="far fa-edit me-2"></i>Update Status</button>
              </div>
            </div>
          </CardBody>
        </Card>
      </>
    )
  }

  GetPercentages(Data) {
    const Total = Data.reduce((sum, item) => sum + item.LikelyImpact, 0);
    const Percentages = Data.map(item => { return { PercId: item.PerspectiveId, Perc: (item.LikelyImpact / Total ? item.LikelyImpact / Total : 0) * 100 } });
    let Singles = Data.reduce((Singles, item) => {
      if (!Singles)
        Singles = [];
      const idx = Singles.findIndex(c => c.LikelyImpact === item.LikelyImpact);
      if (idx < 0)
        Singles.push(item);
      else
        Singles.splice(idx, 1);
      return Singles;
    }, 0);
    let RoundedPercentages = Percentages.map(item => { return { ...item, Perc: (Math.round(item.Perc * 100) / 100) } });
    const Adjustment = 100 - RoundedPercentages.reduce((sum, item) => sum + item.Perc, 0);
    if (Adjustment != 0) {
      Singles = Singles.map(c => c.PerspectiveId);
      const updateIdx = RoundedPercentages.findIndex(c => Singles.includes(c.PercId));

      if (updateIdx != -1)
        RoundedPercentages[updateIdx].Perc += Adjustment;
    }
    return RoundedPercentages
  }

  GetPerspectivePerc(Percentages, PercId) {
    let idx = -1;
    if (Percentages && Percentages.length > 0)
      idx = Percentages.findIndex(c => c.PercId === PercId);
    if (idx > -1)
      return CustomFixedDecimals(Percentages[idx].Perc);
    return 0;
  }

  render() {
    const content = this.state.loading ? <em>Loading Evaluations</em> : this.renderEvaluationView(this.state.CurrentView);
    //console.log(this.state, this.props.CurrentView)
    return (<>
      {content}

      <Modal isOpen={this.state.riskEvaluateModal} toggle={this.toggleRiskEvaluate} className={this.props.className} scrollable size="md" backdrop="static" >
        <ModalHeader toggle={this.toggleRiskEvaluate} close={<button className="close" onClick={this.toggleRiskEvaluate}><i className="fas fa-times"></i></button>}>Evaluate the Risk</ModalHeader>
        <ModalBody>
          <div className='row'>
            {this.state.RiskEvaluateStatusData?.RiskId ? <>
              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbPreviousPercentage' name='tbPreviousPercentage' placeholder='Previous Percentage' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.PreviousPercentage} />
              </div>
              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbModifiedBy' name='tbModifiedBy' placeholder='Modified By' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.UserName} />
              </div>
              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbPreviousDate' name='tbPreviousDate' placeholder='Modified Date' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.PreviousDate} />
              </div>
              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbPreviousComment' name='tbPreviousComment' placeholder='Previous Comment' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.PreviousComment} />
              </div>
            </> : ""}

            <div className='mb-3'>
              <NumericTextBoxComponent id='tbRiskAnalysis' name='tbRiskAnalysis' min={0} max={100} placeholder='Percentage' floatLabelType='Always' showClearButton={true} value={this.state.Percentage} onChange={e => this.setState({ Percentage: e.target.value })} /> <div id='tbRiskAnalysisError' className='error-message' />
            </div>
            <div className='mb-3'>
              <TextBoxComponent multiline id='tbRiskAnalysisComment' name='tbRiskAnalysisComment' placeholder='Comment' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Note} onChange={e => this.setState({ Note: e.target.value })} /> <div id='tbRiskAnalysisCommentError' className='error-message' />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={this.toggleRiskEvaluate}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={() => this.saveData(this.state.RiskEvaluateStatusId)}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal >
    </>
    )
  }
}