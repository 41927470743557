
import { useEffect, useState } from 'react';
import TooltipComponent from '../../Common/ToolTip';
import Attachments from './Attachments';
import Findings from './Findings';
import { getUserDetails } from '../../../helpers/authentication';
import { GetContrastColour, GetRatingBandId } from '../../../helpers/global';



const RiskSummary = ({ RiskData, CompanyData, RiskEvaluations, EventHandler }) => {
  const statuses = [{ Title: "Draft", Id: 0 }, { Title: "Active", Id: 1 }, { Title: "Deactivated", Id: 2 }];

  const [potentialClass, setPotentialClass] = useState('d-none');

  useEffect(() => {
    setPotentialClass(CompanyData.PotentialRiskUsed === 1 ? "" : "d-none");
    //CompanyData.PotentialRiskUsed === 1
  }, [CompanyData]);

  const renderStatusBlock = (Title, EventMethod, OverallRating, TotalLikelyImpact) => {
    
    const bandId = GetRatingBandId(TotalLikelyImpact, RiskEvaluations.RiskImpactRatings);

    if (bandId >= 0) {
      const band = RiskEvaluations.RiskImpactRatings[bandId];
      return (
        <div onClick={() => EventHandler(EventMethod)} style={{ cursor: "pointer" }} className='risk-box'>
          
          {OverallRating ? <div style={{ backgroundColor: band.ImpactBandColour, color: GetContrastColour(band.ImpactBandColour) }}>{OverallRating + " - " + band.ImpactBandName} <br />{getUserDetails().Currency + " " + TotalLikelyImpact.toNumberString()}</div>
            : <div>Not Yet Started</div>}
        </div>
      )
    }
    else {
      return (
        <div onClick={() => EventHandler(EventMethod)} style={{ cursor: "pointer" }} className='risk-box'>
          
          {OverallRating ? <div>{"Below minumum threshold"} <br />{getUserDetails().Currency + " " + TotalLikelyImpact.toNumberString()}</div>
            : <div>Not Yet Started</div>}
        </div>
      )
    }
  }


  return (
    <>
      <div className='card bg-light mb-3'>
        <div className='card-body pb-0'>
          <div className='row risk-status my-3'>
            <div className='col-md-4'>
              <Attachments Id={RiskData.Id} Type={1} />
              <Findings Id={RiskData.Id} Type={1} />

              <i className="fa-regular fa-circle-user text-danger" id="icoResponsible"></i>
              <TooltipComponent id="ttResponsible" target="icoResponsible" content={`Responsible Person: ${RiskData.ResponsiblePersonName}<br>Responsible Team: ${RiskData.ResponsibleTeamName}`} />

              <i className="fa-regular fa-circle-user text-primary" id="icoLogging"></i>
              <TooltipComponent id="ttLogging" target="icoLogging" content={`Logging Person: ${RiskData.AddedByName}<br>Logging Team: ${RiskData.AddedByTeamName}<br>Logging Date: ${new Date(RiskData.DateAdded).toDateString()}`} />

              <i className="fas fa-info-circle" id="icoDescription"></i>
              <TooltipComponent id="ttDescription" target="icoDescription" content={'<b>DESCRIPTION</b><br>'+RiskData.Description} />

              <i className="far fa-lightbulb" id="icoOpportunities"></i>
              <TooltipComponent id="ttOpportunities" target="icoOpportunities" content={'<b>OPPORTUNITIES</b><br>' +RiskData.Opportunities} />

              <i className="fas fa-chart-line" id="icoIndicators"></i>
              <TooltipComponent id="ttIndicators" target="icoIndicators" content={'<b>INDICATORS</b><br>' +RiskData.Indicators} />

            </div>
            <div className='col-md-4 text-center'><h5>RISK # {RiskData.RiskNumber}</h5></div>
            <div className="col-md-4 text-end">
              <span className={'risk-status-box ' + (statuses.find(item => item.Id === RiskData.Status)?.Title.toLowerCase())}>{statuses.find(item => item.Id === RiskData.Status)?.Title}</span>
            </div>
          </div>
          <div className='row d-none'>
            <div className='mb-3 col-md-8 col-lg-3'>
              <div className="card risk-detail">
                <div className="card-body">Short Name</div>
                <div className="card-footer">{RiskData.Name}</div>
              </div>

            </div>
            <div className='mb-3 col-md-4 col-lg-2'>
              <div className="card risk-detail">
                <div className="card-body">Type</div>
                <div className="card-footer">{RiskData.RiskTypeName}</div>
              </div>
            </div>
            <div className='mb-3 col-md-5 col-lg-2'>
              <div className="card risk-detail">
                <div className="card-body">Category</div>
                <div className="card-footer">{RiskData.RiskCategoryName}</div>
              </div>
            </div>
            <div className='mb-3 col-md-5 col-lg-3'>
              <div className="card risk-detail">
                <div className="card-body">Site</div>
                <div className="card-footer">{RiskData.SiteStructureName}</div>
              </div>
            </div>
            <div className='mb-3 col-md-2 col-lg-2'>
              <div className="card risk-detail">
                <div className="card-body">Onset</div>
                <div className="card-footer">{RiskData.SpeedOfOnset + "m"}</div>
              </div>
            </div>
          </div>


          <table className="table table-sm table-bordered table-risk-summary">
            <thead>
              <tr>
                <th>Short Name</th>
                <th>Type</th>
                <th>Category</th>
                <th>Site</th>
                <th>Onset</th>
                <th className={potentialClass}>Potential</th>
                <th>Actual</th>
                <th>Target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{RiskData.Name}</td>
                <td>{RiskData.RiskTypeName}</td>
                <td>{RiskData.RiskCategoryName}</td>
                <td>{RiskData.SiteStructureName}</td>
                <td>{RiskData.SpeedOfOnset + "m"}</td>
                <td className={potentialClass}>{renderStatusBlock("POTENTIAL", "potential", RiskEvaluations.PotentialData.OverallRating, RiskEvaluations.PotentialData.TotalLikelyImpact)}</td>
                <td>{renderStatusBlock("ACTUAL", "actual", RiskEvaluations.ActualData.OverallRating, RiskEvaluations.ActualData.TotalLikelyImpact)}</td>
                <td>{renderStatusBlock("TARGET", "target", RiskEvaluations.TargetData.OverallRating, RiskEvaluations.TargetData.TotalLikelyImpact)}</td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>
    </>
  );
};

export default RiskSummary;