import { AssuranceProviders } from "./components/Admin/AssuranceProviders";
import { AssuranceResults } from "./components/Admin/AssuranceResults";
import { BusinessObjectives } from "./components/Admin/BusinessObjectives";
import { BusinessProcesses } from "./components/Admin/BusinessProcesses";
import { Capitals } from "./components/Admin/Capitals";
import { Companies } from "./components/Admin/Companies";
import { MasterData } from "./components/Admin/Dashboard";
//import { ImpactBand } from "./components/Admin/ImpactBand";
import { EvaluationPerspectives } from "./components/Admin/EvaluationPerspectives";
import { FormatsOfAssurances } from "./components/Admin/FormatsOfAssurances";
import { Frequencies } from "./components/Admin/Frequencies";
import { ImpactBand } from "./components/Admin/ImpactBand";
import { ImpersonateCompany } from "./components/Admin/ImpersonateCompany";
import { LinesOfAssurances } from "./components/Admin/LinesOfAssurances";
import { RiskCategories } from "./components/Admin/RiskCategories";
//import { RiskImpactRating } from "./components/Admin/RiskImpactRating";
import RiskRatingTables from "./components/Admin/RiskRatingTables";
import ImpactPerspectiveDiz from "./components/Admin/ImpactPerspectiveDiz";
import { Risks } from "./components/Admin/Risks";
import { RiskStatuses } from "./components/Admin/RiskStatuses";
import { RiskTypes } from "./components/Admin/RiskTypes";
import { SiteStructures } from "./components/Admin/SiteStructures";
import { Stakeholders } from "./components/Admin/Stakeholders";
import { Teams } from "./components/Admin/Teams";
import { UserData } from "./components/Admin/UserData";
import { Counter } from "./components/Counter";
import HealthAnalyseRisk from "./components/Dashboard/Charts/HealthAnalyseRisk";
import HealthCaptureControl from "./components/Dashboard/Charts/HealthCaptureControl";
import HealthDoAssurance from "./components/Dashboard/Charts/HealthDoAssurance";
import HealthPlanAssurance from "./components/Dashboard/Charts/HealthPlanAssurance";
import HealthTreatFindings from "./components/Dashboard/Charts/HealthTreatFindings";
import HealthTreatRisk from "./components/Dashboard/Charts/HealthTreatRisk";
import OutputAssuranceResults from "./components/Dashboard/Charts/OutputAssuranceResults";
import ResultAssurancePlan from "./components/Dashboard/Charts/ResultAssurancePlan";
import ResultGapClosingAction from "./components/Dashboard/Charts/ResultGapClosingAction";
import ResultRiskProfile from "./components/Dashboard/Charts/ResultRiskProfile";
import Home from "./components/Dashboard/Home";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { DefaultPage } from "./components/DefaultPage";
import { FetchData } from "./components/FetchData";
import { MyStuff } from "./components/Processing/MyStuff";
import { ControlList } from "./components/Risk/ControlList";
import  ItemsList  from "./components/Risk/ItemsList";
import { Risk } from "./components/Risk/Risk";
import RiskEditor from "./components/Risk/Risk-Editor";
import { TestStuff } from "./components/TestStuff";


const AppRoutes = [
  { index: true, element: <Home /> },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/counter', element: <Counter /> },
  { path: '/fetch-data', element: <FetchData /> },
  { path: '/my-items', element: <ItemsList /> },
  { path: '/control-list', element: <ControlList /> },
  { path: '/risk-edit/:riskNumber', element: <RiskEditor /> },
  { path: '/risk-old/:riskNumber', element: <Risk /> },
  { path: '/processing', element: <MyStuff /> },

  //DASHBOARD ROUTES
  { path: "/health-analyse-risk", element: <HealthAnalyseRisk /> },
  { path: "/health-treat-risk", element: <HealthTreatRisk /> },
  { path: "/health-capture-control", element: <HealthCaptureControl /> },
  { path: "/health-treat-findings", element: <HealthTreatFindings /> },
  { path: "/health-plan-assurance", element: <HealthPlanAssurance /> },
  { path: "/health-do-assurance", element: <HealthDoAssurance /> },
  { path: "/result-risk-profile", element: <ResultRiskProfile /> },
  { path: "/result-assurance-plan", element: <ResultAssurancePlan /> },
  { path: "/result-assurance-results", element: <OutputAssuranceResults /> },
  { path: "/result-gap-closing", element: <ResultGapClosingAction /> },


  { path: "/test-stuff", element: <TestStuff /> },
];

export const AdminRoutes = [
  { index: true, element: <MasterData /> },
  { path: '/companies', element: <Companies /> },
  { path: '/users', element: <UserData /> },
  { path: '/business-objectives', element: <BusinessObjectives /> },
  { path: '/business-processes', element: <BusinessProcesses /> },
  { path: '/capitals', element: <Capitals /> },
  { path: '/risk-categories', element: <RiskCategories /> },
  { path: '/risk-statuses', element: <RiskStatuses /> },
  { path: '/risk-types', element: <RiskTypes /> },
  { path: '/stakeholders', element: <Stakeholders /> },
  { path: '/teams', element: <Teams /> },
  { path: '/site-structure', element: <SiteStructures /> },
  { path: '/tooltips', element: <DefaultPage /> },
  { path: '/impersonate-company', element: <ImpersonateCompany /> },
  { path: '/potential-risk-used', element: <DefaultPage /> },
  { path: '/evaluation-perspectives', element: <EvaluationPerspectives /> },
  { path: '/assurance-providers', element: <AssuranceProviders /> },
  { path: '/assurance-results-or-conclusions', element: <AssuranceResults /> },
  { path: '/format-of-assurances', element: <FormatsOfAssurances /> },
  { path: '/automated-full-review-template', element: <DefaultPage /> },
  { path: '/lines-of-assurances', element: <LinesOfAssurances /> },
  { path: '/frequencies', element: <Frequencies /> },
  { path: '/risks', element: <Risks /> },
  { path: '/rating-tables', element: <RiskRatingTables /> },
  { path: '/impact-bands', element: <ImpactBand /> },
  { path: '/impact-perspective-diz', element: <ImpactPerspectiveDiz /> },

];

export default AppRoutes;
