import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getUserDetails } from '../../helpers/authentication';



export class MasterData extends Component {

  componentDidMount() {
    document.title = "Master Data / Administration :: OnPoint RMS";
  }

  render() {
    return (
      <div className="container-fluid">
        <h1>Master Data / Administration</h1>
        <div className="row">
          {getUserDetails().UserRole > 9 ?
            <div className="col-md-6 col-lg-4 pb-4">
              <div className="card card-bordered h-100">
                <div className="card-header">
                  <h5 className="card-title">General</h5>
                </div>
                <div className="card-body pb-0" style={{ paddingBottom: "0 !import" }}>
                  <Link to="/admin/companies" className="btn btn-primary btn-sm w-100 me-3 mb-3">Companies</Link>
                  <Link to="/admin/site-structure" className="btn btn-primary btn-sm w-100 me-3 mb-3">Sites</Link>
                  <Link to="/admin/teams" className="btn btn-primary btn-sm w-100 me-3 mb-3">Teams</Link>
                  <Link to="/admin/users" className="btn btn-primary btn-sm w-100 me-3 mb-3">Users</Link>
                  {/*<Link to="/admin/frequencies" className="btn btn-primary btn-sm w-100 me-3 mb-3">Frequencies</Link>*/}
                  <Link to="/admin/risk-statuses" className="btn btn-primary btn-sm w-100 me-3 mb-3">Progress Status</Link>
                </div>
              </div>
            </div> : ""}

          <div className="col-md-6 col-lg-4 pb-4">
            <div className="card card-bordered h-100">
              <div className="card-header">
                <h5 className="card-title">Risk Categorisation</h5>
              </div>
              <div className="card-body">
                <Link to="/admin/risk-types" className="btn btn-primary w-100 btn-sm me-3 mb-3">Risk Types</Link>
                <Link to="/admin/risk-categories" className="btn btn-primary w-100 btn-sm me-3 mb-3">Risk Categories</Link>
                <Link to="/admin/business-objectives" className="btn btn-primary w-100 btn-sm me-3 mb-3">Business Objectives</Link>
                <Link to="/admin/business-processes" className="btn btn-primary w-100 btn-sm me-3 mb-3">Business Processes</Link>
                <Link to="/admin/stakeholders" className="btn btn-primary w-100 btn-sm me-2 mb-3">Stakeholders</Link>
                <Link to="/admin/capitals" className="btn btn-primary w-100 btn-sm me-3 mb-3">Capitals</Link>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 pb-4">
            <div className="card card-bordered h-100">
              <div className="card-header">
                <h5 className="card-title">Evaluation</h5>
              </div>
              <div className="card-body">
                <Link to="/admin/impact-bands" className="btn btn-primary w-100 btn-sm me-3 mb-3">Impact Bands</Link>
                <Link to="/admin/evaluation-perspectives" className="btn btn-primary w-100 btn-sm me-3 mb-3">Evaluation Perspectives</Link>
                <Link to="/admin/impact-perspective-diz" className="btn btn-primary w-100 btn-sm me-3 mb-3">Persective Descriptions for the Rating Bands</Link>
                <Link to="/admin/rating-tables" className="btn btn-primary w-100 btn-sm me-3 mb-3">Impact Rating Tables</Link>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 pb-4">
            <div className="card card-bordered h-100">
              <div className="card-header">
                <h5 className="card-title">Assurance</h5>
              </div>
              <div className="card-body">
                <Link to="/admin/lines-of-assurance" className="btn btn-primary w-100 btn-sm me-3 mb-3">Lines of Assurance </Link>
                <Link to="/admin/assurance-providers" className="btn btn-primary w-100 btn-sm me-3 mb-3">Assurance Providers></Link>
                <Link to="/admin/assurance-results-or-conclusions" className="btn btn-primary w-100 btn-sm me-3 mb-3">Assurance Conclusions</Link>
                <Link to="/admin/format-of-assurances" className="btn btn-primary w-100 btn-sm me-3 mb-3">Assurance Types</Link>
                
                {/*<Link to="/admin/automated-full-review-template" className="btn btn-primary w-100 btn-sm me-3 mb-3">Automated 'Full Review' Template</Link>*/}
              </div>
            </div>
          </div>
        </div>

      </div>

    );
  }
}
