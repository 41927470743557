import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { ConfirmDialogStyles, GenerateSiteStructureTree, SearchGridToolbar } from '../../../helpers/global';
import { fieldRequired } from '../../../helpers/validation';
import { confirm } from "react-confirm-box";
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent, DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { LoadAPIData, SaveAPIData, DeleteAPIData } from '../../../helpers/DataHelper';
import { useNavigate } from 'react-router-dom';
//import { useLocation, useNavigate } from "react-router-dom";

const RiskProperties = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [editId, setEditId] = useState(0);
  const [riskNumber, setRiskNumber] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [riskTypeId, setRiskTypeId] = useState();
  const [riskCategoryId, setRiskCategoryId] = useState(0);
  const [riskStatusId, setRiskStatusId] = useState(0);
  const [siteStructureId, setSiteStructureId] = useState(0);
  const [speedOfOnset, setSpeedOfOnset] = useState(12);
  const [responsiblePersonId, setResponsiblePersonId] = useState(0);
  const [responsibleTeamId, setResponsibleTeamId] = useState(0);
  const [hasOpportunities, setHasOpportunities] = useState(false);
  const [opportunities, setOpportunities] = useState('');
  const [hasIndicators, setHasIndicators] = useState(false);
  const [indicators, setIndicators] = useState('');
  const [deactivationReason, setDeactivationReason] = useState('');
  const [status, setStatus] = useState(0);
  const [addedBy, setAddedBy] = useState(0);
  const [addedByTeamId, setAddedByTeamId] = useState(0);

  const [users, setUsers] = useState();
  const [teams, setTeams] = useState();
  const [riskTypes, setRiskTypes] = useState();
  const [riskCategories, setRiskCategories] = useState();
  const [riskStatuses, setRiskStatuses] = useState();
  const [siteStructures, setSiteStructures] = useState();
  const [siteStructureFields, setSiteStructureFields] = useState(null);
  const [teamFields, setTeamFields] = useState(null);
  const [deleteClass, setDeleteClass] = useState('me-auto');

  const [isLoading, setIsLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);

  const toggle = () => setEditModal(!editModal);

  const ShowEditor = (RiskId) => {
    //console.log(`Editing Risk with ID: ${RiskId}`);
    if (RiskId === 0)
      setDeleteClass("d-none");
    else
      setDeleteClass("me-auto");
    editItem(RiskId);
    setEditModal(true);
    setIsLoading(false);
  }

  useImperativeHandle(ref, () => ({
    ShowEditor,
  }));

  const loadData = async () => {
    const userData = getUserDetails();
    let data = await LoadAPIData(`api/userdata/getbycompany/${userData.CompanyId}/${userData.UserRole}`);
    setUsers(data?.map(item => ({ ...item, FullName: `${item.FirstName} ${item.LastName}` })));

    data = await LoadAPIData(`api/teams/getcompanyteams/${userData.CompanyId}/${userData.UserRole}`);
    //console.log(data);
    setTeams(data.map(item => ({ ...item, SelectedUsers: JSON.parse(item.SelectedUsers) })));
    setTeamFields({ dataSource: GenerateSiteStructureTree(data, false), value: 'id', text: 'name', parentValue: "pid", hasChildren: 'hasChild' })

    setRiskTypes(await LoadAPIData(`api/risktypes/listallbycompany/${userData.CompanyId}/${userData.UserRole}`));
    setRiskCategories(await LoadAPIData(`api/riskcategories/listallbycompany/${userData.CompanyId}/${userData.UserRole}`));
    setRiskStatuses(await LoadAPIData(`api/riskstatuses/listallbycompany/${userData.CompanyId}/${userData.UserRole}`))
    data = await LoadAPIData(`api/sitestructures/listallbycompany/${userData.CompanyId}/${userData.UserRole}`)
    setSiteStructures(data);

    const fields = { dataSource: GenerateSiteStructureTree(data, false), value: 'id', text: 'name', parentValue: "pid", hasChildren: 'hasChild' }
    setSiteStructureFields(fields);
  }

  const editItem = async (id) => {
    if (id > 0) {
      const data = await LoadAPIData(`api/risks/${id}`)
      //console.log(data);
      setEditId(data.Id);
      
      setRiskNumber(data.RiskNumber);
      setName(data.Name);
      setDescription(data.Description);
      setRiskTypeId(data.RiskTypeId);
      setRiskCategoryId(data.RiskCategoryId);
      setRiskStatusId(data.RiskStatusId);
      setSiteStructureId(parseInt(data.SiteStructureId));
      //setSiteStructureId(0);
      setSpeedOfOnset(data.SpeedOfOnset);
      setResponsiblePersonId(data.ResponsiblePersonId);
      setResponsibleTeamId(parseInt(data.ResponsibleTeamId));
      //setResponsibleTeamId(0);

      setHasOpportunities(data.HasOpportunities);
      setOpportunities(data.Opportunities);
      setHasIndicators(data.HasIndicators);
      setIndicators(data.Indicators);
      setDeactivationReason(data.DeactivationReason);
      
      setAddedBy(data.AddedBy);
      //setAddedByTeamId(data.AddedByTeamId);
      setAddedByTeamId(0);
      
      setStatus(data.Status);

    }
    else {
      //clear state fields
      setEditId(0);

      setRiskNumber('');
      setName('');
      setDescription('');
      setRiskTypeId(0);
      setRiskCategoryId(0);
      if (riskStatuses && riskStatuses.length > 0)
        setRiskStatusId(riskStatuses[0].Id);
      else
        setRiskStatusId(0);
      setSiteStructureId(0);
      setSpeedOfOnset(12);
      setResponsiblePersonId(getUserDetails().Id);
      setResponsibleTeamId(getUserDetails().TeamId);
      setHasOpportunities(false);
      setOpportunities('');
      setHasIndicators(false);
      setIndicators('');
      setDeactivationReason('');
      
      setAddedBy(0);
      setAddedByTeamId(0);
      
      setStatus(0);
      
    }
    console.log(riskStatuses);
    setEditModal(true);
  };

  const validateForm = () => {
    var valid = true;
    //valid &= fieldRequired(companyId, 'tbCompanyIdError', '* required');
    //valid &= fieldRequired(riskNumber, 'tbRiskNumberError', '* required');
    valid &= fieldRequired(name, 'tbNameError', '* required');
    valid &= fieldRequired(description, 'tbDescriptionError', '* required');
    //valid &= fieldRequired(riskTypeId, 'tbRiskTypeIdError', '* required');
    //valid &= fieldRequired(riskCategoryId, 'tbRiskCategoryIdError', '* required');
    //valid &= fieldRequired(riskStatusId, 'tbRiskStatusIdError', '* required');
    //valid &= fieldRequired(siteStructureId, 'tbSiteStructureIdError', '* required');
    //valid &= fieldRequired(speedOfOnset, 'tbSpeedOfOnsetError', '* required');
    //valid &= fieldRequired(responsiblePersonId, 'tbResponsiblePersonIdError', '* required');
    //valid &= fieldRequired(responsibleTeamId, 'tbResponsibleTeamIdError', '* required');
    //valid &= fieldRequired(hasOpportunities, 'tbHasOpportunitiesError', '* required');
    //valid &= fieldRequired(opportunities, 'tbOpportunitiesError', '* required');
    //valid &= fieldRequired(hasIndicators, 'tbHasIndicatorsError', '* required');
    //valid &= fieldRequired(indicators, 'tbIndicatorsError', '* required');
    ////valid &= fieldRequired(deactivationReason, 'tbDeactivationReasonError', '* required');
    //valid &= fieldRequired(addedByTeamId, 'tbAddedByTeamIdError', '* required');

    return valid;
  }

  const saveRisk = async (engageRisk) => {
    if (validateForm()) {
      const userData = getUserDetails();

      const data = { id: editId, companyId: userData.CompanyId, riskNumber: riskNumber, name: name, description: description, riskTypeId: riskTypeId, riskCategoryId: riskCategoryId, riskStatusId: riskStatusId, siteStructureId: siteStructureId, speedOfOnset: speedOfOnset, responsiblePersonId: responsiblePersonId, responsibleTeamId: responsibleTeamId, hasOpportunities: hasOpportunities, opportunities: opportunities, hasIndicators: hasIndicators, indicators: indicators, deactivationReason: deactivationReason, addedBy: addedBy, addedByTeamId: addedByTeamId, status: status, };
      //console.log(data);
      const result = await SaveAPIData('/api/risks', data, (editId === 0 ? 'POST' : 'PUT'));
      if (result.success) {
        setEditId(result.savedId.Item1);
        //console.log(result.savedId);
        
        ToastUtility.show({
          title: 'Risks', content: 'The risk was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
        if (engageRisk)
          navigate("/risk-edit/" + result.savedId.Item2);
        else
          return result.savedId.Item1;
      }
      else {
        ToastUtility.show({
          title: 'Risks', content: 'There was an error saving the risk!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    }
  }

  const saveItem = async (event) => {
    event.stopPropagation();
    saveRisk(false);
  }

  const saveAndEngage = async (event) => {
    event.stopPropagation();
    saveRisk(true);
  }

  const deleteItem = async (dataId) => {
    if (await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles)) {
      const userData = getUserDetails();
      const result = await DeleteAPIData('/api/rootcauses/' + userData.UserRole + "/" + dataId)
      if (result) {
        //loadData();
        
        ToastUtility.show({
          title: 'Root Causes', content: 'The root cause was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
        //const navigate = useNavigate();
      }
      else {
        ToastUtility.show({
          title: 'Root Causes', content: 'There was an error deleting the root cause!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  
  if (!isLoading)
    return (
      <>
        <Modal isOpen={editModal} toggle={toggle} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={toggle}>Edit Risk</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='mb-3'>
                <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={name} onChange={e => setName(e.value)} />
                <div id='tbNameError' className='error-message' />
              </div>

              <div className='mb-3'>
                <TextBoxComponent id='tbDescription' multiline name='tbDescription' placeholder='Description' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={description} onChange={e => setDescription(e.value)} />
                <div id='tbDescriptionError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddRiskTypeId' name='ddRiskTypeId' placeholder='Risk Type' dataSource={[{ Title: "- Select -", Id: 0 }, ...riskTypes]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={riskTypeId} onChange={e => setRiskTypeId(e.value)} />
                <div id='tbRiskTypeIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddRiskCategoryId' name='ddRiskCategoryId' placeholder='Risk Category' dataSource={[{ Title: "- Select -", Id: 0 }, ...riskCategories]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={riskCategoryId} onChange={e => setRiskCategoryId(e.value)} />
                <div id='tbRiskCategoryIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddRiskStatusId' name='ddRiskStatusId' placeholder='Risk Status' dataSource={riskStatuses} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={riskStatusId} onChange={e => setRiskStatusId(e.value)} />
                <div id='tbRiskStatusIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownTreeComponent fields={siteStructureFields} id='ddSiteStructureId' name='ddSiteStructureId' showClearButton={false} placeholder='Site' floatLabelType='Always' select={e => setSiteStructureId(e.itemData.id)} value={[siteStructureId]} />
                <div id='tbSiteStructureIdError' className='error-message' />
              </div>
            </div>

            <div className='row'>
              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddResponsiblePersonId' name='ddResponsiblePersonId' placeholder='Responsible Person' dataSource={users} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={responsiblePersonId} onChange={e => setResponsiblePersonId(e.value)} />
                <div id='tbResponsiblePersonIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                {/*<DropDownListComponent id='ddResponsibleTeamId' name='ddResponsibleTeamId' placeholder='Responsible Team' dataSource={teams} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={responsibleTeamId} onChange={e => setResponsibleTeamId(e.value)} />*/}
                <DropDownTreeComponent fields={teamFields} id='ddResponsibleTeamId' name='ddResponsibleTeamId' showClearButton={false} placeholder='Responsible Team' floatLabelType='Always' value={[responsibleTeamId]} select={e => setResponsibleTeamId(e.itemData.id)} />
                <div id='tbResponsibleTeamIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <CheckBoxComponent id='cbHasOpportunities' name='cbHasOpportunities' type='checkbox' label='Has Opportunities' checked={hasOpportunities} change={e => setHasOpportunities(e.checked)} />
              </div>

              <div className='mb-3 col-md-6'>
                <CheckBoxComponent id='cbHasIndicators' name='cbHasIndicators' type='checkbox' label='Has Indicators' checked={hasIndicators} change={e => setHasIndicators(e.checked)} />
              </div>

              {hasOpportunities ? <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbOpportunities' name='tbOpportunities' multiline={true} placeholder='Opportunities' type='text' rows={5} maxLength='2500' floatLabelType='Always' showClearButton={true} value={opportunities} onChange={e => setOpportunities(e.value)} />
                <div id='tbOpportunitiesError' className='error-message' />
              </div> : <div className='mb-3 col-md-6'></div>}

              {hasIndicators && <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbIndicators' name='tbIndicators' multiline={true} placeholder='Indicators' type='text' rows={5} maxLength='2500' floatLabelType='Always' showClearButton={true} value={indicators} onChange={e => setIndicators(e.value)} />
                <div id='tbIndicatorsError' className='error-message' />
              </div>}
            </div>

            <div className='row'>
              <div className='mb-3 col-md-4'>
                <NumericTextBoxComponent id='tbSpeedOfOnset' name='tbSpeedOfOnset' placeholder='Speed Of Onset (Months)' min={1} step={1} format="n0" floatLabelType='Always' showClearButton={true} value={speedOfOnset} onChange={e => setSpeedOfOnset(e.value)} />
                <div id='tbSpeedOfOnsetError' className='error-message' />
              </div>
              
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddStatusId' name='ddStatusId' placeholder='Status' dataSource={[{ Name: "Draft", Id: 0 }, { Name: "Active", Id: 1 }, { Name: "Deactivated", Id: 2 }]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={status} change={e => setStatus(e.value)} />
                <div id='ddStatusIdError' className='error-message' />
              </div>
            </div>
            {editId > 0 && status === 2 ? <div className='mb-3'>
              <TextBoxComponent id='tbDeactivationReason' name='tbDeactivationReason' placeholder='Deactivation Reason' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={deactivationReason} onChange={e => setDeactivationReason(e.value)} />
              <div id='tbDeactivationReasonError' className='error-message' />
            </div> : ""}





          </ModalBody>
          <ModalFooter>

            <Button color="danger" size="sm" onClick={() => deleteItem(editId)} className={deleteClass}>Delete <i className="far fa-trash-alt ms-2"></i></Button>
            <Button color="dark" size="sm" onClick={toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={saveAndEngage}>Save &amp; Engage <i className="far fa-circle-right ms-2"></i></Button>
          </ModalFooter>
        </Modal>


      </>
    );
});

export default RiskProperties;