import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent, DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { CommandTemplate, GenerateSiteStructureTree, SearchGridToolbar, ShowLoader } from '../../helpers/global';
import { fieldRequired } from '../../helpers/validation';

export class Risks extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    
    this.state = {
      editData: [], loading: true, editModal: false, viewModal: false,
      Id: 0,
      CompanyId: 0,
      RiskNumber: '',
      Name: '',
      Description: '',
      RiskTypeId: 0,
      RiskCategoryId: 0,
      RiskStatusId: 0,
      SiteStructureId: 0,
      SpeedOfOnset: 0,
      ResponsiblePersonId: 0,
      ResponsibleTeamId: 0,
      HasOpportunities: false,
      Opportunities: '',
      HasIndicators: false,
      Indicators: '',
      DeactivationReason: '',
      DateAdded: new Date(),
      AddedBy: 0,
      AddedByTeamId: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      CompanyList: [],
      RiskCategoryList: [],
      RiskStatusList: [],
      RiskTypeList: [],
      SiteStructureList: [],
      TeamList: [],
      UserList: [],
      SelectedItem: 0,

    };
    this.toggle = this.toggle.bind(this);
    this.viewToggle = this.viewToggle.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  viewToggle() {
    this.setState({
      viewModal: !this.state.viewModal
    });
  }

  componentDidMount() {
    document.title = "Risks Administration :: OnPoint RMS";
    this.loadData();
  }

  //addItem = (ev) => { this.editItem(0); }

  editItem = (id) => {
    //console.log(id)
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({ Id: data.Id, CompanyId: data.CompanyId, RiskNumber: data.RiskNumber, Name: data.Name, Description: data.Description, RiskTypeId: data.RiskTypeId, RiskCategoryId: data.RiskCategoryId, RiskStatusId: data.RiskStatusId, SiteStructureId: data.SiteStructureId, SpeedOfOnset: data.SpeedOfOnset, ResponsiblePersonId: data.ResponsiblePersonId, ResponsibleTeamId: data.ResponsibleTeamId, HasOpportunities: data.HasOpportunities, Opportunities: data.Opportunities, HasIndicators: data.HasIndicators, Indicators: data.Indicators, DeactivationReason: data.DeactivationReason, DateAdded: data.DateAdded, AddedBy: data.AddedBy, AddedByTeamId: data.AddedByTeamId, DateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        CompanyId: 0,
        RiskNumber: '',
        Name: '',
        Description: '',
        RiskTypeId: 0,
        RiskCategoryId: 0,
        RiskStatusId: 0,
        SiteStructureId: 0,
        SpeedOfOnset: 0,
        ResponsiblePersonId: 0,
        ResponsibleTeamId: 0,
        HasOpportunities: false,
        Opportunities: '',
        HasIndicators: false,
        Indicators: '',
        DeactivationReason: '',
        DateAdded: new Date(),
        AddedBy: 0,
        AddedByTeamId: 0,
        DateModified: new Date(),
        ModifiedBy: 0,
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    //valid &= fieldRequired(this.state.CompanyId, 'tbCompanyIdError', '* required');
    //valid &= fieldRequired(this.state.RiskNumber, 'tbRiskNumberError', '* required');
    valid &= fieldRequired(this.state.Name, 'tbNameError', '* required');
    valid &= fieldRequired(this.state.Description, 'tbDescriptionError', '* required');
    valid &= fieldRequired(this.state.RiskTypeId, 'tbRiskTypeIdError', '* required');
    valid &= fieldRequired(this.state.RiskCategoryId, 'tbRiskCategoryIdError', '* required');
    valid &= fieldRequired(this.state.RiskStatusId, 'tbRiskStatusIdError', '* required');
    valid &= fieldRequired(this.state.SiteStructureId, 'tbSiteStructureIdError', '* required');
    valid &= fieldRequired(this.state.SpeedOfOnset, 'tbSpeedOfOnsetError', '* required');
    valid &= fieldRequired(this.state.ResponsiblePersonId, 'tbResponsiblePersonIdError', '* required');
    valid &= fieldRequired(this.state.ResponsibleTeamId, 'tbResponsibleTeamIdError', '* required');
    //valid &= fieldRequired(this.state.HasOpportunities, 'tbHasOpportunitiesError', '* required');
    //valid &= fieldRequired(this.state.Opportunities, 'tbOpportunitiesError', '* required');
    //valid &= fieldRequired(this.state.HasIndicators, 'tbHasIndicatorsError', '* required');
    //valid &= fieldRequired(this.state.Indicators, 'tbIndicatorsError', '* required');
    valid &= fieldRequired(this.state.DeactivationReason, 'tbDeactivationReasonError', '* required');
    valid &= fieldRequired(this.state.AddedByTeamId, 'tbAddedByTeamIdError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.Id === this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.setState({
          DateAdded: data.DateAdded,
          AddedBy: data.AddedBy,
          Id: data.Id,
          Status: data.Status
        });
      }
      else {
        this.setState({
          Status: 1
        });
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {

      case 'Delete':
        this.deleteItem(args.rowData.Id);
        break;
      case 'edit':
        this.editItem(args.rowData.Id);
        break;
      default:
        break;
    }
  }

  static renderDataTable(data, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={SearchGridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='RiskNumber' width='50' headerText="Risk #" />
          <ColumnDirective field='Name' width='100' headerText="Risk Name" />
          <ColumnDirective field='Description' width='100' headerText="Risk Description" />
          <ColumnDirective field='RiskTypeName' width='50' headerText="Type" />
          <ColumnDirective field='RiskStatusName' width='50' headerText="Status" />
          <ColumnDirective field='RiskCategoryName' width='50' headerText="Category" />
          <ColumnDirective field='SiteStructureName' width='50' headerText="Site" />
          <ColumnDirective headerText='Actions' width='100' commands={CommandTemplate} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {
    let contents = this.state.loading ? ShowLoader() : Risks.renderDataTable(this.state.editData,  this.onGridCommand);
    let siteStructureFields = { dataSource: GenerateSiteStructureTree(this.state.SiteStructureList, false), value: 'id', text: 'name', parentValue: "pid", hasChildren: 'hasChild' };
    //console.log(this.state)
    return (
      <>
        <div className="container-fluid">
          <Row>
            <Col xs={6}>
              <h1>Risks </h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Button color="primary" size="sm" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle}  scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Risk</ModalHeader>
          <ModalBody>

            <div className='row'>

              <div className='mb-3'>
                <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.Name} onChange={e => this.setState({ Name: e.target.value })} /> <div id='tbNameError' className='error-message' />
              </div>

              <div className='mb-3'>
                <TextBoxComponent id='tbDescription' name='tbDescription' placeholder='Description' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Description} onChange={e => this.setState({ Description: e.target.value })} /> <div id='tbDescriptionError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddRiskTypeId' name='ddRiskTypeId' placeholder='Risk Type' dataSource={[{ Title: "-SELECT-", Id: 0 }, ...this.state.RiskTypeList]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.RiskTypeId} change={e => this.setState({ RiskTypeId: e.value })} /><div id='tbRiskTypeIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddRiskCategoryId' name='ddRiskCategoryId' placeholder='Risk Category' dataSource={[{ Title: "-SELECT-", Id: 0 }, ...this.state.RiskCategoryList]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.RiskCategoryId} change={e => this.setState({ RiskCategoryId: e.value })} /><div id='tbRiskCategoryIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddRiskStatusId' name='ddRiskStatusId' placeholder='Risk Status' dataSource={[{ Title: "-SELECT-", Id: 0 }, ...this.state.RiskStatusList]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.RiskStatusId} change={e => this.setState({ RiskStatusId: e.value })} /><div id='tbRiskStatusIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                {/*<DropDownListComponent id='ddSiteStructureId' name='ddSiteStructureId' placeholder='Site Structure' dataSource={[{ Name: "-SELECT-", Id: 0 }, ...this.state.SiteStructureList]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.SiteStructureId} change={e => this.setState({ SiteStructureId: e.value })} /><div id='tbSiteStructureIdError' className='error-message' />*/}
                <DropDownTreeComponent fields={siteStructureFields} id='ddSiteStructureId' name='ddSiteStructureId' showClearButton={false} placeholder='Site Structure' floatLabelType='Always' select={e => this.setState({ SiteStructureId: e.itemData.id })} value={[this.state.SiteStructureId]} /><div id='tbSiteStructureIdError' className='error-message' />
              </div>
            </div>

            <div className='row'>
              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddResponsiblePersonId' name='ddResponsiblePersonId' placeholder='Responsible Person' dataSource={[{ FullName: "-SELECT-", Id: 0 }, ...this.state.UserList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.ResponsiblePersonId} change={e => this.setState({ ResponsiblePersonId: e.value })} /><div id='tbResponsiblePersonIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddResponsibleTeamId' name='ddResponsibleTeamId' placeholder='Responsible Team' dataSource={[{ Name: "-SELECT-", Id: 0 }, ...this.state.TeamList]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.ResponsibleTeamId} change={e => this.setState({ ResponsibleTeamId: e.value })} /><div id='tbResponsibleTeamIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <CheckBoxComponent id='cbHasOpportunities' name='cbHasOpportunities' type='checkbox' label='Has Opportunities' checked={this.state.HasOpportunities} onChange={e => this.setState({ HasOpportunities: e.target.checked })} /> &nbsp; <div id='cbHasOpportunitiesError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <CheckBoxComponent id='cbHasIndicators' name='cbHasIndicators' type='checkbox' label='Has Indicators' checked={this.state.HasIndicators} onChange={e => this.setState({ HasIndicators: e.target.checked })} /> &nbsp; <div id='cbHasIndicatorsError' className='error-message' />
              </div>

              {this.state.HasOpportunities ? <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbOpportunities' name='tbOpportunities' placeholder='Opportunities' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Opportunities} onChange={e => this.setState({ Opportunities: e.target.value })} /> <div id='tbOpportunitiesError' className='error-message' />
              </div> : <div className='mb-3 col-md-6'></div>}

              {this.state.HasIndicators && <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbIndicators' name='tbIndicators' placeholder='Indicators' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Indicators} onChange={e => this.setState({ Indicators: e.target.value })} /> <div id='tbIndicatorsError' className='error-message' />
              </div>}
            </div>

            <div className='row'>
              <div className='mb-3 col-md-6'>
                <NumericTextBoxComponent id='tbSpeedOfOnset' name='tbSpeedOfOnset' placeholder='Speed Of Onset' floatLabelType='Always' showClearButton={true} value={this.state.SpeedOfOnset} onChange={e => this.setState({ SpeedOfOnset: e.target.value })} /> <div id='tbSpeedOfOnsetError' className='error-message' />
              </div>
              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddAddedByTeamId' name='ddAddedByTeamId' placeholder='Added By Team' dataSource={[{ Name: "-SELECT-", Id: 0 }, ...this.state.TeamList]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.AddedByTeamId} change={e => this.setState({ AddedByTeamId: e.value })} /><div id='tbAddedByTeamIdError' className='error-message' />
              </div>
            </div>
            <div className='mb-3'>
              <TextBoxComponent id='tbDeactivationReason' name='tbDeactivationReason' placeholder='Deactivation Reason' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.DeactivationReason} onChange={e => this.setState({ DeactivationReason: e.target.value })} /> <div id='tbDeactivationReasonError' className='error-message' />
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    try {
      const response = await fetch('api/userdata/getbycompany/' + userData.CompanyId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data?.map(item => ({ ...item, FullName: `${item.FirstName} ${item.LastName}` }))
        this.setState({ UserList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/teams/getcompanyteams/' + userData.CompanyId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data.map(item => ({ ...item, SelectedUsers: JSON.parse(item.SelectedUsers) }))
        this.setState({ TeamList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/risktypes', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskTypeList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/riskcategories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskCategoryList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/riskstatuses', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskStatusList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/sitestructures', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ SiteStructureList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/companies', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ CompanyList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/risks/getallcompanyrisks/' + userData.CompanyId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    //setAuthToken(getAuthToken(), new Date());
  }

  async saveData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, showSuccess: false });
    const userData = getUserDetails();

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Id: this.state.Id, CompanyId: userData.CompanyId, RiskNumber: this.state.RiskNumber, Name: this.state.Name, Description: this.state.Description, RiskTypeId: this.state.RiskTypeId, RiskCategoryId: this.state.RiskCategoryId, RiskStatusId: this.state.RiskStatusId, SiteStructureId: this.state.SiteStructureId, SpeedOfOnset: this.state.SpeedOfOnset, ResponsiblePersonId: this.state.ResponsiblePersonId, ResponsibleTeamId: this.state.ResponsibleTeamId, HasOpportunities: this.state.HasOpportunities, Opportunities: this.state.Opportunities, HasIndicators: this.state.HasIndicators, Indicators: this.state.Indicators, DeactivationReason: this.state.DeactivationReason, DateAdded: this.state.DateAdded, AddedBy: userData.Id, AddedByTeamId: this.state.AddedByTeamId, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, }

    try {
      const response = await fetch('api/risks', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Risks', content: 'The risks was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risks', content: 'There was an error saving the risks!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/risks/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Risks', content: 'The risks was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risks', content: 'There was an error deleting the risks!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

