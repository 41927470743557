import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { Context } from '../../../helpers/Context';
import ThrobbleHelper from '../../../helpers/ThrobbleHelper';

class HealthTreatFindings extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CompanyId: 0,
            Title: '',
            GraphTitle: '',
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            DisplayOrder: 0,

        };
        this.toggle = this.toggle.bind(this);

    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    componentDidMount() {
        document.title = "Data Health - Treat The Findings";
        this.loadData();
    }

    editItem = async (data, title) => {

      const editData = data.map((item) => ({ ...item, DateAdded: new Date(item.DateAdded).toProperDate() }));
        this.setState({ GraphTitle: title, GraphData: editData, editModal: true });
    };

    getFindingCounts = (risks, findings) => {
        let risksWithFindings = 0;
        let risksWithoutFindings = 0;

        const risksArray = [];

        risks.forEach((risk) => {
            const hasFindings = findings.some((finding) => finding.FindingFromId === risk.Id);
            const newRisk = { ...risk, Result: '' };

            if (hasFindings) {
                risksWithFindings++;
                newRisk.Result = 'Findings available';
            } else {
                risksWithoutFindings++;
                newRisk.Result = 'No findings';
            }
            risksArray.push(newRisk);
        });

        return {
            counts: [
                { name: 'Findings available', value: risksWithFindings },
                { name: 'No findings', value: risksWithoutFindings },
            ],
            risks: risksArray,
        };
    }

    getFindingActionCounts = (risks, findingActions) => {
        let risksWithCompleteFindingActions = 0;
        let risksWithIncompleteFindingActions = 0;

        const risksArray = [];

        risks.forEach((risk) => {
            const relatedActions = findingActions.filter(
                (action) => action.RiskId === risk.Id && action.Percentage === 100
            );
            const newRisk = { ...risk, Result: '' };

            if (relatedActions.length > 0) {
                risksWithCompleteFindingActions++;
                newRisk.Result = '100% FA';
            } else {
                risksWithIncompleteFindingActions++;
                newRisk.Result = 'Incomplete FA';
            }
            risksArray.push(newRisk);
        });

        return {
            counts: [
                { name: '100% FA', value: risksWithCompleteFindingActions },
                { name: 'Incomplete FA', value: risksWithIncompleteFindingActions },
            ],
            risks: risksArray,
        };
    }

    getAnalysisStatusCounts = (risks, findings) => {
        let greaterThan3 = 0;
        let threeFindings = 0;
        let twoFindings = 0;
        let oneFinding = 0;

        const risksArray = [];

        risks.forEach((risk) => {
            const relatedFindings = findings.filter((finding) => finding.FindingFromId === risk.Id);
            const newRisk = { ...risk, Result: '' };

            const numberOfFindings = relatedFindings.length;
            if (numberOfFindings > 3) {
                greaterThan3++;
                newRisk.Result = '>3 findings';
                risksArray.push(newRisk);
            } else if (numberOfFindings === 3) {
                threeFindings++;
                newRisk.Result = '3 findings';
                risksArray.push(newRisk);
            } else if (numberOfFindings === 2) {
                twoFindings++;
                newRisk.Result = '2 findings';
                risksArray.push(newRisk);
            } else if (numberOfFindings === 1) {
                oneFinding++;
                newRisk.Result = '1 finding';
                risksArray.push(newRisk);
            }
        });

        return {
            counts: [
                { name: '>3 findings', value: greaterThan3 },
                { name: '3 findings', value: threeFindings },
                { name: '2 findings', value: twoFindings },
                { name: '1 finding', value: oneFinding },
            ],
            risks: risksArray,
        };
    }

    getLateCounts = (risks, findingActions) => {
        let ahead = 0;
        let behind = 0;

        const risksArray = [];

        risks.forEach((risk) => {
            const relatedFindingActions = findingActions.filter((action) => action.RiskId === risk.Id);

            relatedFindingActions.forEach((action) => {
                const currentDate = new Date();
                const dueDate = new Date(action.DateDue);
                const newRisk = { ...risk, Result: '', FindingActionNumber: '' };

                if (action.Percentage !== 100) {
                    if (dueDate > currentDate) {
                        ahead++;
                        newRisk.Result = 'Ahead of due date';
                        newRisk.FindingActionNumber = action.FindingActionNumber;
                        risksArray.push(newRisk);
                    } else {
                        behind++;
                        newRisk.Result = 'Behind due date';
                        newRisk.FindingActionNumber = action.FindingActionNumber;
                        risksArray.push(newRisk);
                    }
                }
            });
        });

        return {
            counts: [
                { name: 'Ahead of due date', value: ahead },
                { name: 'Behind due date', value: behind },
            ],
            risks: risksArray,
        };
    }

    getInconsistentCounts = (risks, findingActions) => {
        let behindIncomplete = 0;
        let aheadIncomplete = 0;

        const risksArray = [];

        risks.forEach((risk) => {
            const relatedFindingActions = findingActions.filter((action) => action.RiskId === risk.Id);

            const hasIncompleteBehind = relatedFindingActions.some(
                (action) => action.Percentage !== 100 && new Date(action.DateDue) < new Date()
            );

            const hasIncompleteAhead = relatedFindingActions.some(
                (action) => action.Percentage !== 100 && new Date(action.DateDue) > new Date()
            );
            const newRisk = { ...risk, Result: '' };

            if (hasIncompleteBehind) {
                behindIncomplete++;
                newRisk.Result = 'Behind due date & incomplete';
                risksArray.push(newRisk);
            }

            if (hasIncompleteAhead) {
                aheadIncomplete++;
                newRisk.Result = 'Ahead of due date but incomplete';
                risksArray.push(newRisk);
            }
        });

        return {
            counts: [
                { name: 'Behind due date & incomplete', value: behindIncomplete },
                { name: 'Ahead of due date but incomplete', value: aheadIncomplete },
            ],
            risks: risksArray,
        };
    }

    renderTable(data) {
        //console.log(data)
        return (
            <div className='mb-3' >
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className='fw-bolder mb-0 w-50'>Total No. of Risks: {data?.length}</p>

                            <Button color='primary' size="sm" onClick={(e) => this.ExportReport(e, data)}><i className='far fa-file-excel me-2'></i> Export To Excel</Button>
                        </div>
                        <GridComponent dataSource={data} >
                            <ColumnsDirective>
                                <ColumnDirective headerText='Risk Responsible Team' field='ResponsibleTeamName'></ColumnDirective>
                                <ColumnDirective headerText='Risk Responsible Person' field='ResponsiblePersonName'></ColumnDirective>
                                <ColumnDirective headerText='Risk No.' width='70' template={(props) => <Link to={"/risk-edit/" + props.RiskNumber} >{props.RiskNumber}</Link>}></ColumnDirective>
                                <ColumnDirective headerText='Risk Name' field='Name'></ColumnDirective>
                                <ColumnDirective headerText='Logged By' field='AddedByName'></ColumnDirective>
                                <ColumnDirective headerText='Date Logged' field='DateAdded'></ColumnDirective>
                                {data.some(obj => obj.hasOwnProperty('Result')) && <ColumnDirective headerText='Result' field='Result'></ColumnDirective>}
                                {data.some(obj => obj.hasOwnProperty('FindingActionNumber')) && <ColumnDirective headerText='FA No.' field='FindingActionNumber'></ColumnDirective>}
                            </ColumnsDirective>
                        </GridComponent>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderDataTable(data, data2, data3, data4, data5, colors, colors2, pieColors) {
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {(percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : ""}
                </text>
            );
        };
        return (
            <>
                <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data.risks, "Which risks have findings?")}>
                        <strong>Which risks have findings?</strong>
                        <ResponsiveContainer width="100%" height={300}>
                            < PieChart width={500} height={300} >
                                <Pie data={data.counts} dataKey="value" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" labelLine={false} label={renderCustomizedLabel}>
                                    {data.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>

                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data2.risks, "Do the risks with findings have adequate finding actions?")}>
                        <strong>Do the risks with findings have adequate finding actions?</strong>
                        <ResponsiveContainer width="100%" height={300}>
                            < PieChart width={500} height={300} >
                                <Pie data={data2.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#82ca9d" label={renderCustomizedLabel} >
                                    {data2.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data3.risks, "Which risks have multiple findings?")}>
                        <strong>Which risks have multiple findings?</strong>
                        <ResponsiveContainer width="100%" height={400}>
                            < PieChart width={500} height={300} >
                                <Pie data={data3.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#8884d8" label={renderCustomizedLabel} >
                                    {data3.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors2[index % colors2.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>

                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data4.risks, "Which risks with findings have actions that are running late?")}>
                        <strong>Which risks with findings have actions that are running late?</strong>
                        <ResponsiveContainer width="100%" height={400}>
                            < PieChart width={500} height={300} >
                                <Pie data={data4.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#82ca9d" label={renderCustomizedLabel} >
                                    {data4.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data5.risks, "Are there any risks with finding actions with inconsistent tracking information?")}>
                        <strong>Are there any risks with finding actions with inconsistent tracking information?</strong>
                        <ResponsiveContainer width="100%" height={400}>
                            < PieChart width={500} height={300} >
                                <Pie data={data5.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#8884d8" label={renderCustomizedLabel} >
                                    {data5.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>
                </div>
            </>
        );
    }

    render() {
        let riskFindings;
        let riskFindingActions;
        let riskMultiFindings;
        let lateFindingActions;
        let inconsistentFindingActions;

        const colors = ['#c71585', '#ffb6c1'];
        const colors2 = ['#B22222', '#FF0000', '#F08080', '#ffc0cb'];
        const pieColors = ['#32cd32', '#ffb6c1'];

        if (!this.state.loading) {
            riskFindings = this.getFindingCounts(this.state.editData, this.state.FindingList);
            riskFindingActions = this.getFindingActionCounts(this.state.editData, this.state.FindingActionList);

            riskMultiFindings = this.getAnalysisStatusCounts(this.state.editData, this.state.FindingList);
            lateFindingActions = this.getLateCounts(this.state.editData, this.state.FindingActionList);

            inconsistentFindingActions = this.getInconsistentCounts(this.state.editData, this.state.FindingActionList);

        }
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(riskFindings, riskFindingActions, riskMultiFindings, lateFindingActions, inconsistentFindingActions, colors, colors2, pieColors);
        //console.log(this.state, evaluationStatuses, barData)
        return (
            <>
                <div className="container-fluid">
                    <Row>
                        <Col xs={6}>
                            <h2>Data Health<br /><small>- Treat The Findings -</small></h2>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Link to="/" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Go Back</Link>
                            <Button color='primary' size='sm' onClick={(e) => this.handleExport(e, riskFindings.counts, riskFindingActions.counts, riskMultiFindings.counts, lateFindingActions.counts, inconsistentFindingActions.counts)}><i className='far fa-file-pdf me-2'></i>Export to PDF</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {contents}
                        </Col>
                    </Row>
                </div>
                <div>

                </div>
                {<Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="xl" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>{this.state.GraphTitle}</ModalHeader>
                    <ModalBody>
                        {this.state.editModal && this.renderTable(this.state.GraphData)}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal >}
                <ThrobbleHelper />
            </>

        );
    }

    async loadData() {
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();
        const userData = getUserDetails();

        try {
            const response = await fetch(`api/findings/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ FindingList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/findingactions/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ FindingActionList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskanalysisstatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ RiskAnalysisStatusData: data });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/risks/getallcompanyrisks/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ editData: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        //setAuthToken(getAuthToken(), new Date());
    }

    handleExport = async (e, riskFindings, riskFindingActions, riskMultiFindings, lateFindingActions, inconsistentFindingActions) => {
        e.stopPropagation();
        ThrobbleHelper.ToggleThrobble(true, "Downloading Charts");
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();
        const userData = getUserDetails();

        let postData = {
            PieData: JSON.stringify(riskFindings),
            Pie2Data: JSON.stringify(riskFindingActions),
            Pie3Data: JSON.stringify(riskMultiFindings),
            Pie4Data: JSON.stringify(lateFindingActions),
            Pie5Data: JSON.stringify(inconsistentFindingActions),
            BarData: JSON.stringify([]),
            Bar2Data: JSON.stringify([]),
        }

        try {
            const response = await fetch('api/risks/downloadhealthtreatfinding', {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postData)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Charts', content: 'The charts was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });
            } else {
                if (response.status === 401) {
                    ThrobbleHelper.ToggleThrobble(false);
                    handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                }
            }
        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        //setAuthToken(getAuthToken(), new Date());
    }

    async ExportReport(evt, data) {
        //const { handleNavigate } = this.context;
        const finalData = data.map(item => ({
            ResponsibleTeamName: item.ResponsibleTeamName,
            ResponsiblePersonName: item.ResponsiblePersonName,
            RiskNumber: item.RiskNumber,
            RiskName: item.Name,
            DateLogged: item.DateAdded,
            LoggedBy: item.AddedByName,
            Result: item.Result,
            FindingActionNumber: item.FindingActionNumber,
        }));
        evt.stopPropagation();
        var bearer = 'Bearer ' + getAuthToken();
        ThrobbleHelper.ToggleThrobble(true, "Exporting Table");
        try {

            //console.log(data)
            const response = await fetch("api/risks/datahealthtreatfinding", {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(finalData)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Table', content: 'The table was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });

            } else {
                if (response.status === 401) {
                    //handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                };
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        //setAuthToken(getAuthToken(), new Date());
    }
}

export default HealthTreatFindings;