import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { Context } from '../../../helpers/Context';
import ThrobbleHelper from '../../../helpers/ThrobbleHelper';

class ResultRiskProfile extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
      editData: [], loading: true, editModal: false, editControlModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      CompanyId: 0,
      Title: '',
      GraphTitle: '',
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      DisplayOrder: 0,

    };
    this.toggle = this.toggle.bind(this);
    this.toggleControl = this.toggleControl.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  toggleControl() {
    this.setState({
      editControlModal: !this.state.editControlModal
    });
  }

  componentDidMount() {
    document.title = "Output - Risk Profile";
    this.loadData();
  }

  editItem = async (data, title) => {

    const editData = data.map((item) => ({ ...item, DateAdded: new Date(item.DateAdded).toProperDate() }));
    this.setState({ GraphTitle: title, GraphData: editData, editModal: true });
  };

  editControlItem = async (data, title, type) => {

    this.setState({ GraphTitle: title, GraphData: data, Type: type, editControlModal: true });
  };

  getRiskByCapitalsCounts = (risks, capitals, riskCapitals) => {
    const riskCounts = [];
    const capitalsArray = [];

    capitals.forEach(capital => {
      const matchingRiskCapitals = riskCapitals.filter(rc => rc.CapitalsId === capital.Id);
      const matchingRiskIds = matchingRiskCapitals.map(rc => rc.RiskId);
      const matchingRisks = risks.filter(risk => matchingRiskIds.includes(risk.Id));

      riskCounts.push({
        name: capital.Title,
        value: matchingRisks.length,
      });

      capitalsArray.push({ ...capital, Result: matchingRisks.length > 1 ? `${matchingRisks.length} Risks` : matchingRisks.length == 1 ? `${matchingRisks.length} Risk` : "Not yet categorized" })
    });

    const notCategorizedRiskIds = capitals.filter(risk => !riskCapitals.some(rc => rc.CapitalsId === risk.Id)).map(risk => risk.Id);
    const notCategorizedRisks = capitals.filter(risk => notCategorizedRiskIds.includes(risk.Id));

    riskCounts.unshift({
      name: "Not yet categorized",
      value: notCategorizedRisks.length,
    });

    return {
      counts: riskCounts,
      capitals: capitalsArray,
    };
  }

  getRiskStakeholdersCounts = (risks, stakeholders, riskStakeholders) => {
    const riskCounts = [];
    const stakeholdersArray = [];

    stakeholders.forEach(stakeholder => {
      const matchingRiskStakeholders = riskStakeholders.filter(rs => rs.StakeholderId === stakeholder.Id);
      const matchingRiskIds = matchingRiskStakeholders.map(rs => rs.RiskId);
      const matchingRisks = risks.filter(risk => matchingRiskIds.includes(risk.Id));

      riskCounts.push({
        name: stakeholder.Title,
        value: matchingRisks.length,
      });

      stakeholdersArray.push({ ...stakeholder, Result: matchingRisks.length > 1 ? `${matchingRisks.length} Risks` : matchingRisks.length == 1 ? `${matchingRisks.length} Risk` : "Not yet categorized" })
    });

    const notCategorizedRiskIds = stakeholders.filter(risk => !riskStakeholders.some(rs => rs.StakeholderId === risk.Id)).map(risk => risk.Id);
    const notCategorizedRisks = stakeholders.filter(risk => notCategorizedRiskIds.includes(risk.Id));

    riskCounts.unshift({
      name: "Not yet categorized",
      value: notCategorizedRisks.length,
    });

    return {
      counts: riskCounts,
      stakeholders: stakeholdersArray,
    };
  }

  getRisksBySiteCounts = (risks, sites) => {
    const riskCounts = [];
    const risksArray = [];

    sites.forEach(site => {
      const matchingRisks = risks.filter(risk => risk.SiteStructureId === site.Id);

      riskCounts.push({
        name: site.Name,
        value: matchingRisks.length,
      });

      risksArray.push(...matchingRisks);
    });

    return {
      counts: riskCounts,
      risks: risksArray,
    };
  }

  getBPCounts = (risks, businessProcesses, riskBusinessProcesses) => {
    const riskCounts = [];
    const risksArray = [];

    //console.log(risks, businessProcesses, riskBusinessProcesses)
    businessProcesses.forEach(bp => {
      const matchingRiskBusinessProcesses = riskBusinessProcesses.filter(rbp => rbp.BusinessProcessId === bp.Id);
      const matchingRiskIds = matchingRiskBusinessProcesses.map(rbp => rbp.RiskId);
      const matchingRisks = risks.filter(risk => matchingRiskIds.includes(risk.Id));
      riskCounts.push({
        name: bp.ShortName,
        value: matchingRisks.length,
      });

      risksArray.push(...matchingRisks.map(risk => ({ ...risk, Result: bp.ShortName })));
    });

    const notCategorizedRiskIds = risks.filter(risk => !riskBusinessProcesses.some(rbp => rbp.RiskId === risk.Id)).map(risk => risk.Id);
    const notCategorizedRisks = risks.filter(risk => notCategorizedRiskIds.includes(risk.Id));

    riskCounts.push({
      name: "Not yet categorized",
      value: notCategorizedRisks.length,
    });

    risksArray.push(...notCategorizedRisks.map(risk => ({ ...risk, Result: "Not yet categorized" })))

    return {
      counts: riskCounts,
      risks: risksArray,
    };
  }

  getBOCounts = (risks, businessObjectives, riskBusinessObjectives) => {
    const riskCounts = [];
    const risksArray = [];

    businessObjectives.forEach(bo => {
      const matchingRiskBusinessObjectives = riskBusinessObjectives.filter(rbo => rbo.BusinessObjectiveId === bo.Id);
      const matchingRiskIds = matchingRiskBusinessObjectives.map(rbo => rbo.RiskId);
      const matchingRisks = risks.filter(risk => matchingRiskIds.includes(risk.Id));

      riskCounts.push({
        name: bo.Title,
        value: matchingRisks.length,
      });

      risksArray.push(...matchingRisks.map(risk => ({ ...risk, Result: bo.Title })));
    });

    const notCategorizedRiskIds = risks.filter(risk => !riskBusinessObjectives.some(rbo => rbo.RiskId === risk.Id)).map(risk => risk.Id);
    const notCategorizedRisks = risks.filter(risk => notCategorizedRiskIds.includes(risk.Id));

    riskCounts.push({
      name: "Not yet categorized",
      value: notCategorizedRisks.length,
    });

    risksArray.push(...notCategorizedRisks.map(risk => ({ ...risk, Result: "Not yet categorized" })))

    //console.log(risksArray)

    return {
      counts: riskCounts,
      risks: risksArray,
    };
  }

  renderTable(data) {
    //console.log(data)
    return (
      <div className='mb-3' >
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className='fw-bolder mb-0 w-50'>Total No. of Risks: {data?.length}</p>

              <Button color='primary' size="sm" onClick={(e) => this.ExportReport(e, data)}><i className='far fa-file-excel me-2'></i> Export To Excel</Button>
            </div>
            <GridComponent dataSource={data} >
              <ColumnsDirective>
                <ColumnDirective headerText='Risk Responsible Team' field='ResponsibleTeamName'></ColumnDirective>
                <ColumnDirective headerText='Risk Responsible Person' field='ResponsiblePersonName'></ColumnDirective>
                <ColumnDirective headerText='Risk No.' width='70' template={(props) => <Link to={"/risk-edit/" + props.RiskNumber} >{props.RiskNumber}</Link>}></ColumnDirective>
                <ColumnDirective headerText='Risk Name' field='Name'></ColumnDirective>
                <ColumnDirective headerText='Logged By' field='AddedByName'></ColumnDirective>
                <ColumnDirective headerText='Date Logged' field='DateAdded'></ColumnDirective>
                {data.some(obj => obj.hasOwnProperty('Result')) && <ColumnDirective headerText='Results' field='Result'></ColumnDirective>}
              </ColumnsDirective>
            </GridComponent>
          </CardBody>
        </Card>
      </div>
    );
  }

  renderControlTable(data, type) {
    //console.log(data)
    return (
      <div className='mb-3' >
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className='fw-bolder mb-0 w-50'>Total No. of {type == 1 ? "Capitals" : "Stakeholders"}: {data?.length}</p>

              <Button color='primary' size="sm" onClick={(e) => this.ExportReport2(e, data)}><i className='far fa-file-excel me-2'></i> Export To Excel</Button>
            </div>
            <GridComponent dataSource={data} >
              <ColumnsDirective>
                <ColumnDirective headerText='Title' field='Title' ></ColumnDirective>
                <ColumnDirective headerText='Description' field='Description'></ColumnDirective>
                {data.some(obj => obj.hasOwnProperty('Result')) && <ColumnDirective headerText='Result' field='Result'></ColumnDirective>}
              </ColumnsDirective>
            </GridComponent>
          </CardBody>
        </Card>
      </div>
    );
  }

  renderDataTable(data, data2, data3, data4, data5, colors, colors2) {
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {(percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : ""}
        </text>
      );
    };
    return (
      <Row>
        <Col xs={12} className="card bg-dark mb-3">
          <div className="text-center my-3 text-light" onClick={() => this.editItem(data.risks, "Risks by site")}>
            <strong>Risks by site</strong>
            <ResponsiveContainer width="100%" height={400}>
              < BarChart width={500} height={300} data={data.counts} >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" activeBar={<Rectangle fill="midnightblue" stroke="grey" />} label={{ position: 'top' }}>
                  {data.counts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={"royalblue"} />
                  ))}
                </Bar>
              </BarChart >
            </ResponsiveContainer>
          </div>
        </Col>

        <Col xs={12} className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
          <div className="text-center my-3 text-light" onClick={() => this.editControlItem(data2.capitals, "Capitals threatened by risks", 1)}>
            <strong>Capitals threatened by risks</strong>
            <ResponsiveContainer width="100%" height={300}>
              < PieChart width={500} height={300} >
                <Pie data={data2.counts} dataKey="value" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" labelLine={false} label={renderCustomizedLabel}>
                  {data2.counts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart >
            </ResponsiveContainer>
          </div>

          <div className="text-center my-3 text-light" onClick={() => this.editControlItem(data3.stakeholders, "Stakeholders threatened by risks", 2)}>
            <strong>Stakeholders threatened by risks</strong>
            <ResponsiveContainer width="100%" height={300}>
              < PieChart width={500} height={300} >
                <Pie data={data3.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#82ca9d" label={renderCustomizedLabel} >
                  {data3.counts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart >
            </ResponsiveContainer>
          </div>
        </Col>

        <Col xs={12} className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3">
          <div className="text-center my-3 w-50 text-light" onClick={() => this.editItem(data4.risks, "Risks by business process")}>
            <strong>Risks by business process</strong>
            <ResponsiveContainer width="100%" height={400}>
              < BarChart width={500} height={300} data={data4.counts} >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" activeBar={<Rectangle fill="midnightblue" stroke="grey" />} label={{ position: 'top' }}>
                  {data4.counts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={data4.length - 1 == index ? "red" : "royalblue"} />
                  ))}
                </Bar>
              </BarChart >
            </ResponsiveContainer>
          </div>

          <div className="text-center my-3 w-50 text-light" onClick={() => this.editItem(data5.risks, "Business objectives threatened by risks")}>
            <strong>Business objectives threatened by risks</strong>
            <ResponsiveContainer width="100%" height={400}>
              < BarChart width={500} height={300} data={data5.counts} >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" activeBar={<Rectangle fill="midnightblue" stroke="blue" />} label={{ position: 'top' }}>
                  {data5.counts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={data5.length - 1 == index ? "red" : "royalblue"} />
                  ))}
                </Bar>
              </BarChart >
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    let riskBySite;
    let riskCapitals;
    let riskStakeholders;
    let riskBP;
    let riskBO;

    const colors = ['#B22222', '#ff0000', '#ffb6c1', '#32cd32', '#FFD700', '#4169E1', '#228B22', '#00BFFF'];
    const bars = ['royalblue'];

    if (!this.state.loading) {
      riskBySite = this.getRisksBySiteCounts(this.state.editData, this.state.SiteStructureList);
      riskCapitals = this.getRiskByCapitalsCounts(this.state.editData, this.state.CapitalsList, this.state.RiskCapitalData);
      riskStakeholders = this.getRiskStakeholdersCounts(this.state.editData, this.state.StakeholdersList, this.state.RiskStakeholderData);

      riskBP = this.getBPCounts(this.state.editData, this.state.BusinessProcessesList, this.state.RiskBusinessProcessData);
      riskBO = this.getBOCounts(this.state.editData, this.state.BusinessObjectivesList, this.state.RiskBusinessObjectiveData);
    }
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(riskBySite, riskCapitals, riskStakeholders, riskBP, riskBO, colors, bars);
    //console.log(this.state, evaluationStatuses, barData)
    return (
      <>
        <div className="container-fluid">
          <Row>
            <Col xs={6}>
              <h2>Output<br /><small>- Risk Profile -</small></h2>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Link to="/" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Go Back</Link>
              <Button color='primary' size='sm' onClick={(e) => this.handleExport(e, riskBySite.counts, riskCapitals.counts, riskStakeholders.counts, riskBP.counts, riskBO.counts)}><i className='far fa-file-pdf me-2'></i>Export to PDF</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>
        <div>

        </div>
        {<Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="xl" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>{this.state.GraphTitle}</ModalHeader>
          <ModalBody>
            {this.state.editModal && this.renderTable(this.state.GraphData)}
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal >}

        {<Modal isOpen={this.state.editControlModal} toggle={this.toggleControl} className={this.props.className} scrollable size="xl" backdrop="static">
          <ModalHeader toggle={this.toggleControl} close={<button className="close" onClick={this.toggleControl}><i className="fas fa-times"></i></button>}>{this.state.GraphTitle}</ModalHeader>
          <ModalBody>
            {this.state.editControlModal && this.renderControlTable(this.state.GraphData, this.state.Type)}
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggleControl}>Close <i className="far fa-times-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal >}
        <ThrobbleHelper />
      </>

    );
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    try {
      const response = await fetch(`api/sitestructures/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ SiteStructureList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/businessobjectives/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ BusinessObjectivesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/businessprocesses/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ BusinessProcessesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskbusinessobjectives`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskBusinessObjectiveData: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskbusinessprocesses`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskBusinessProcessData: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/capitals/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ CapitalsList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/stakeholders/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ StakeholdersList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskcapitals`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskCapitalData: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskstakeholders`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskStakeholderData: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/risks/getallcompanyrisks/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    //setAuthToken(getAuthToken(), new Date());
  }

  handleExport = async (e, riskBySite, riskCapitals, riskStakeholders, riskBP, riskBO) => {
    e.stopPropagation();
    ThrobbleHelper.ToggleThrobble(true, "Downloading Charts");
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    let postData = {
      PieData: JSON.stringify(riskCapitals),
      Pie2Data: JSON.stringify(riskStakeholders),
      Pie3Data: JSON.stringify(riskBO),
      Pie4Data: JSON.stringify([]),
      Pie5Data: JSON.stringify([]),
      BarData: JSON.stringify(riskBySite),
      Bar2Data: JSON.stringify(riskBP),
    }

    try {
      const response = await fetch('api/risks/downloadoutputriskprofile', {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
      });

      if (response.ok) {
        const data = await response.json();
        const FileData = data.Item1;
        const MimeType = data.Item2;
        const Filename = data.Item3;
        const Base64String = `data:${MimeType};base64,${FileData}`;

        const LinkBtn = document.createElement("a");
        LinkBtn.download = Filename;
        LinkBtn.href = Base64String;
        LinkBtn.click();
        ThrobbleHelper.ToggleThrobble(false);
        ToastUtility.show({
          title: 'Charts', content: 'The charts was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      } else {
        if (response.status === 401) {
          ThrobbleHelper.ToggleThrobble(false);
          handleNavigate("/login");
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          ToastUtility.show({
            title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
          });
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false);
      ToastUtility.show({
        title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }

    //setAuthToken(getAuthToken(), new Date());
  }

  async ExportReport(evt, data) {
    //const { handleNavigate } = this.context;
    const finalData = data.map(item => ({
      ResponsibleTeamName: item.ResponsibleTeamName,
      ResponsiblePersonName: item.ResponsiblePersonName,
      RiskNumber: item.RiskNumber,
      RiskName: item.Name,
      DateLogged: item.DateAdded,
      LoggedBy: item.AddedByName,
      Result: item.Result,
    }));
    evt.stopPropagation();
    var bearer = 'Bearer ' + getAuthToken();
    ThrobbleHelper.ToggleThrobble(true, "Exporting Table");
    try {

      //console.log(data)
      const response = await fetch("api/risks/outputresultriskprofile", {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalData)
      });

      if (response.ok) {
        const data = await response.json();
        const FileData = data.Item1;
        const MimeType = data.Item2;
        const Filename = data.Item3;
        const Base64String = `data:${MimeType};base64,${FileData}`;

        const LinkBtn = document.createElement("a");
        LinkBtn.download = Filename;
        LinkBtn.href = Base64String;
        LinkBtn.click();
        ThrobbleHelper.ToggleThrobble(false);
        ToastUtility.show({
          title: 'Table', content: 'The table was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });

      } else {
        if (response.status === 401) {
          //handleNavigate("/login");
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          ToastUtility.show({
            title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
          });
        };
      }
    } catch (e) {
      console.error(e);
      ThrobbleHelper.ToggleThrobble(false);
      ToastUtility.show({
        title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }

    //setAuthToken(getAuthToken(), new Date());
  }

  async ExportReport2(evt, data) {
    //const { handleNavigate } = this.context;
    const finalData = data.map(item => ({
      Title: item.Title,
      Description: item.Description,
      Result: item.Result,
    }));
    evt.stopPropagation();
    var bearer = 'Bearer ' + getAuthToken();
    ThrobbleHelper.ToggleThrobble(true, "Exporting Table");
    try {

      //console.log(data)
      const response = await fetch("api/risks/outputresultriskprofiles", {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalData)
      });

      if (response.ok) {
        const data = await response.json();
        const FileData = data.Item1;
        const MimeType = data.Item2;
        const Filename = data.Item3;
        const Base64String = `data:${MimeType};base64,${FileData}`;

        const LinkBtn = document.createElement("a");
        LinkBtn.download = Filename;
        LinkBtn.href = Base64String;
        LinkBtn.click();
        ThrobbleHelper.ToggleThrobble(false);
        ToastUtility.show({
          title: 'Table', content: 'The table was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });

      } else {
        if (response.status === 401) {
          //handleNavigate("/login");
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          ToastUtility.show({
            title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
          });
        };
      }
    } catch (e) {
      console.error(e);
      ThrobbleHelper.ToggleThrobble(false);
      ToastUtility.show({
        title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }

    //setAuthToken(getAuthToken(), new Date());
  }
}

export default ResultRiskProfile;