import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { CommandTemplate, CompanySubscriptionTypes, RatingScaleTypes, YesNoTypes } from '../../helpers/global';
import { SetCatastrophicLabel, SetImmaterialityLabel } from '../../helpers/Lookup';
import { fieldRequired } from '../../helpers/validation';

export class Companies extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    
    this.state = {
      editData: [], loading: true, editModal: false, gridToolbar: ['Search'],
      Id: 0,
      CompanyType: 0,
      SubscriptionType: 0,
      Name: '',
      PhysicalAddress: '',
      PostalAddress: '',
      CompanyReg: '',
      VAT: '',
      ContactName: '',
      ContactEmail: '',
      ContactTel: '',
      ContactCell: '',
      CurrencySymbol: '',
      PotentialRiskUsed: 0, 
      RatingScaleUsed: 0,
      ImmaterialityLabel: '',
      CatastrophicLabel: '', 

    };
    this.toggle = this.toggle.bind(this);
    //this.renderDataTable = this.renderDataTable.bind(this);
  }

  
  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Companies :: OnPoint RMS";
    this.loadData();
  }

  addItem = (ev) => { this.editItem(0); }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({ Id: data.Id, CompanyType: data.CompanyType, SubscriptionType: data.SubscriptionType, Name: data.Name, PhysicalAddress: data.PhysicalAddress, PostalAddress: data.PostalAddress, CompanyReg: data.CompanyReg, VAT: data.VAT, ContactName: data.ContactName, ContactEmail: data.ContactEmail, ContactTel: data.ContactTel, ContactCell: data.ContactCell, CurrencySymbol: data.CurrencySymbol, PotentialRiskUsed: data.PotentialRiskUsed, RatingScaleUsed: data.RatingScaleUsed, ImmaterialityLabel: data.ImmaterialityLabel, CatastrophicLabel: data.CatastrophicLabel });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        CompanyType: 0,
        SubscriptionType: 0,
        Name: '',
        PhysicalAddress: '',
        PostalAddress: '',
        CompanyReg: '',
        VAT: '',
        ContactName: '',
        ContactEmail: '',
        ContactTel: '',
        ContactCell: '',
        CurrencySymbol: '',
        PotentialRiskUsed: 0, 
        RatingScaleUsed: 0, 
        ImmaterialityLabel: '',
        CatastrophicLabel: '', 

      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.SubscriptionType, 'tbSubscriptionTypeError', '* required', 'ddSubscriptionType');
    valid &= fieldRequired(this.state.Name, 'tbNameError', '* required', 'tbName');
    valid &= fieldRequired(this.state.CurrencySymbol, 'tbCurrencySymbolError', '* required', 'tbCurrencySymbol');
    valid &= fieldRequired(this.state.PotentialRiskUsed, 'tbPotentialRiskUsedError', '* required', 'ddPotentialRiskUsed');
    valid &= fieldRequired(this.state.RatingScaleUsed, 'tbRatingScaleUsedError', '* required', 'ddRatingScaleUsed');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.Id === this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.setState({
          Id: data.Id,
        });
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.Id);
        break;
      default: //edit
        this.deleteItem(args.rowData.Id);
        break;
    }
  }

 

  static renderDataTable(data, gridToolbar, commandClick) {

    const renderContact = (props) => {
      return (
        <>
          {props.ContactName}
          <div className="small text-muted">
            Email: {props.ContactEmail ? props.ContactEmail: "None"}<br />
            Tel: {props.ContactTel ? props.ContactTel : "None"}<br />
            Cell: {props.ContactCell ? props.ContactCell : "None"}
          </div>
        </>
      );
    };

    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} className="DataGridTop" >
        <ColumnsDirective>
          <ColumnDirective field='Name' width='110' headerText="Name" />
          <ColumnDirective field='SubscriptionType' width='60' headerText="Subs. Type" template={(props) => CompanySubscriptionTypes.find(o => o.Id === props.SubscriptionType).Text} />
          <ColumnDirective field='CurrencySymbol' width='75' headerText="Currency Symbol" textAlign='center' />
          {/*<ColumnDirective field='RatingScaleUsed' width='75' headerText="Rating Scale" template={(props) => RatingScaleTypes.find(o => o.Id === props.RatingScaleUsed).Text} />*/}
          <ColumnDirective field='PotentialRiskUsed' width='75' headerText="Potential Risk" template={(props) => YesNoTypes.find(o => o.value === props.PotentialRiskUsed).text} />
          <ColumnDirective field='ContactName' width='100' headerText="Contact" template={renderContact} disableHtmlEncode={ false} />
          <ColumnDirective headerText='Actions' width='60' commands={CommandTemplate} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {
    
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : Companies.renderDataTable(this.state.editData, this.state.gridToolbar, this.onGridCommand);
    return (
      
      <>
        <div className="container-fluid">
          <Row>
            <Col xs={6}>
              <h1>Companies</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Link to="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Master Data</Link>
              <Button color="primary" size="sm" onClick={this.addItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Company</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className='mb-3 col-md-12'>
                <TextBoxComponent id='tbName' name='tbName' placeholder='Company Name' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.Name} onChange={e => this.setState({ Name: e.target.value })} /> <div id='tbNameError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6 d-none'>
                <NumericTextBoxComponent id='tbCompanyType' name='tbCompanyType' placeholder='Company Type' floatLabelType='Always' value={this.state.CompanyType} change={e => this.setState({ CompanyType: e.target.value })} /> <div id='tbCompanyTypeError' className='error-message' />
              </div>

              <div className='mb-3 col-md-4'>
                <DropDownListComponent id="ddSubscriptionType" dataSource={CompanySubscriptionTypes} fields={{ text: 'Text', value: 'Id' }} placeholder='Subscription Type' floatLabelType='Always' showClearButton={true} value={this.state.SubscriptionType} onChange={e => this.setState({ SubscriptionType: e.value })} /><div id='tbSubscriptionTypeError' className='error-message' />
              </div>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id="ddPotentialRiskUsed" dataSource={YesNoTypes} fields={{ text: 'text', value: 'value' }} placeholder='Potential Risk Viewable' floatLabelType='Always' showClearButton={true} value={this.state.PotentialRiskUsed} onChange={e => this.setState({ PotentialRiskUsed: e.value })} /><div id='tbPotentialRiskUsedError' className='error-message' />
              </div>
              <div className='mb-3 col-md-4 d-none'>
                <DropDownListComponent id="ddRatingScaleUsed" dataSource={RatingScaleTypes} fields={{ text: 'Text', value: 'Id' }} placeholder='Rating Scale Used' floatLabelType='Always' showClearButton={true} value={this.state.RatingScaleUsed} onChange={e => this.setState({ RatingScaleUsed: e.value })} /><div id='tbRatingScaleUsedError' className='error-message' />
              </div>


              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbCompanyReg' name='tbCompanyReg' placeholder='Company Reg' type='text' maxLength='50' floatLabelType='Always' showClearButton={true} value={this.state.CompanyReg} onChange={e => this.setState({ CompanyReg: e.target.value })} /> <div id='tbCompanyRegError' className='error-message' />
              </div>

              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbVAT' name='tbVAT' placeholder='VAT Number' type='text' maxLength='50' floatLabelType='Always' showClearButton={true} value={this.state.VAT} onChange={e => this.setState({ VAT: e.target.value })} /> <div id='tbVATError' className='error-message' />
              </div>

              <div className='mb-3  col-md-4'>
                <TextBoxComponent id='tbCurrencySymbol' name='tbCurrencySymbol' placeholder='Currency Symbol' type='text'  maxLength='10' floatLabelType='Auto' showClearButton={true} value={this.state.CurrencySymbol} onChange={e => this.setState({ CurrencySymbol: e.target.value })} /> <div id='tbCurrencySymbolError' className='error-message' />
              </div>
              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbPhysicalAddress' name='tbPhysicalAddress' placeholder='Physical Address' type='text' multiline={true} maxLength='500' floatLabelType='Always' showClearButton={true} value={this.state.PhysicalAddress} onChange={e => this.setState({ PhysicalAddress: e.target.value })} /> <div id='tbPhysicalAddressError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbPostalAddress' name='tbPostalAddress' placeholder='Postal Address' type='text' multiline={true} maxLength='500' floatLabelType='Always' showClearButton={true} value={this.state.PostalAddress} onChange={e => this.setState({ PostalAddress: e.target.value })} /> <div id='tbPostalAddressError' className='error-message' />
              </div>
              

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbContactName' name='tbContactName' placeholder='Contact Name' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.ContactName} onChange={e => this.setState({ ContactName: e.target.value })} /> <div id='tbContactNameError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbContactEmail' name='tbContactEmail' placeholder='Contact Email' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.ContactEmail} onChange={e => this.setState({ ContactEmail: e.target.value })} /> <div id='tbContactEmailError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbContactTel' name='tbContactTel' placeholder='Contact Tel' type='text' maxLength='50' floatLabelType='Always' showClearButton={true} value={this.state.ContactTel} onChange={e => this.setState({ ContactTel: e.target.value })} /> <div id='tbContactTelError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbContactCell' name='tbContactCell' placeholder='Contact Cell' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.ContactCell} onChange={e => this.setState({ ContactCell: e.target.value })} /> <div id='tbContactCellError' className='error-message' />
              </div>
              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbImmaterialityLabel' name='tbImmaterialityLabel' placeholder='Immateriality Label' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.ImmaterialityLabel} onChange={e => this.setState({ ImmaterialityLabel: e.target.value })} /> <div id='tbImmaterialityLabelError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbCatastrophicLabel' name='tbCatastrophicLabel' placeholder='Catastrophic Label' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.CatastrophicLabel} onChange={e => this.setState({ CatastrophicLabel: e.target.value })} /> <div id='tbCatastrophicLabelError' className='error-message' />
              </div>

            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/companies', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
    //setAuthToken(getAuthToken(), new Date());
  }

  async saveData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, CompanyType: this.state.CompanyType, SubscriptionType: this.state.SubscriptionType, Name: this.state.Name, PhysicalAddress: this.state.PhysicalAddress, PostalAddress: this.state.PostalAddress, CompanyReg: this.state.CompanyReg, VAT: this.state.VAT, ContactName: this.state.ContactName, ContactEmail: this.state.ContactEmail, ContactTel: this.state.ContactTel, ContactCell: this.state.ContactCell, CurrencySymbol: this.state.CurrencySymbol, PotentialRiskUsed: this.state.PotentialRiskUsed, RatingScaleUsed: this.state.RatingScaleUsed, ImmaterialityLabel: this.state.ImmaterialityLabel, CatastrophicLabel: this.state.CatastrophicLabel }

    try {
      const response = await fetch('api/companies', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();

        if (data.Id === getUserDetails().CompanyId) {
          SetCatastrophicLabel(data.CatastrophicLabel);
          SetImmaterialityLabel(data.ImmaterialityLabel);
        }

        ToastUtility.show({
          title: 'Companies', content: 'The company was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Companies', content: 'There was an error saving the company!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/companies/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      console.log(response);
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Companies', content: 'The company was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Companies', content: 'There was an error deleting the company!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

