/* eslint-disable no-case-declarations */
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Inject, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getUserDetails } from '../../helpers/authentication';
import { LoadAPIData } from '../../helpers/DataHelper';
import { SearchGridToolbar } from '../../helpers/global';
import RiskProperties from '../Risk/Common/RiskProperties';


export default function Risks(props) {
  const navigate = useNavigate();
  const risksRef = useRef();
  const [editData, setEditData] = useState();
  
  const adminCommandTemplate = [
    { type: 'Engage', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
    { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-settings e-icons' } },
  ];

  const commandClick = async (args) => {
    
    switch (args.commandColumn.type) {
      case 'Engage':
        viewItem(args.rowData.Id);
        break;
      default: //edit
        editItem(args.rowData.Id);
        break;
    }
  }

  const loadData = async () => {
    const userData = getUserDetails();
    const risks = await LoadAPIData(`api/risks/getallcompanyrisks/${userData.CompanyId}/${userData.UserRole}`);
    setEditData(risks);
  }

  const addItem = () => {
    if (risksRef.current) {
      risksRef.current.ShowEditor(0); 
    }
  }

  const editItem = (id) => {
    //console.log(id);
    if (id > 0) {
      const userData = getUserDetails();
      
      const data = editData.find((item) => { return item.Id === id });
      if (userData.UserRole > 9 || userData.Id === data.ResponsiblePersonId) {
        if (risksRef.current) {
          risksRef.current.ShowEditor(data.Id);
        }  
      } else {
        ToastUtility.show({
          title: 'Risks', content: 'You do not have permission to edit this risk!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }

    }
    else {
      if (risksRef.current) {
        risksRef.current.ShowEditor(0);
      }
    }
    
  };

  const viewItem = (id) => {
    
    const userData = getUserDetails();
    
    if (id > 0) {
      const data = editData.find((item) => { return item.Id === id });
      if (userData.UserRole > 9 || userData.Id === data.ResponsiblePersonId) {
        navigate("/risk-edit/" + data.RiskId);
      } else {
        ToastUtility.show({
          title: 'Risks', content: 'You do not have permission to view this risk!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }
    }
  };
  
  useEffect(() => {
    document.title = "Risks :: OnPoint RMS";
    loadData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-6 "><h1>Risks</h1></div>
        <div className="col-md-6 text-end align-self-center">
          <Button color="primary" size="sm" onClick={addItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
        </div>
      </div>
      
      <GridComponent dataSource={editData} allowSorting={true} toolbar={SearchGridToolbar} commandClick={commandClick} >
        <ColumnsDirective>
          <ColumnDirective field='RiskNumber' width='50' headerText="Risk #" template={(props) => <Link to={"/risk-edit/" + props.RiskId}>{props.RiskNumber}</Link>} />
          <ColumnDirective field='Name' width='80' headerText="Risk Name" />
          <ColumnDirective field='Description' width='100' headerText="Risk Description" />
          <ColumnDirective field='RiskTypeName' width='50' headerText="Type" />
          <ColumnDirective field='StatusName' width='60' headerText="Status" />
          <ColumnDirective field='RiskCategoryName' width='50' headerText="Category" />
          <ColumnDirective field='SiteStructureName' width='50' headerText="Site" />
          <ColumnDirective headerText='Actions' width='70' commands={adminCommandTemplate} />

        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
      <div className="mt-5"></div>
      <RiskProperties ref={risksRef} />

    </>
  );
}

