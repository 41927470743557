import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { ConfirmDialogStyles, ShowLoader } from '../../helpers/global';
import { fieldRequired } from '../../helpers/validation';

export class Teams extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);


    this.state = {
      editData: [], loading: true, editModal: false, userModal: false, isOpen: false, gridToolbar: ['Search'],
      Id: 0,
      Name: '',
      ParentId: 0,
      ParentItem: [],
      CompanyId: getUserDetails().CompanyId,
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      UserList: [],
      TeamCategoryList: [],
      TeamUsers: [],
      SelectedUsers: [],

    };
    this.toggle = this.toggle.bind(this);
    this.toggleUsers = this.toggleUsers.bind(this);
    this.editItem = this.editItem.bind(this);
    this.nodeTemplate = this.nodeTemplate.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal, SelectedUsers: this.state.editId === 0 ? [] : this.state.SelectedUsers, isOpen: false
    });
  }

  toggleUsers() {
    this.setState({ userModal: !this.state.userModal });
  }

  componentDidMount() {
    document.title = "Teams Administration :: OnPoint RMS";
    this.loadData();
  }

  handleChange = (userId) => {
    const data = {
      Id: 0,
      UserId: parseInt(userId),
      TeamId: 0,
      DateAdded: this.state.DateAdded,
      AddedBy: this.state.AddedBy,
      DateModified: this.state.DateModified,
      ModifiedBy: this.state.ModifiedBy,
      Status: this.state.Status,
    }
    if (this.state.SelectedUsers.some(user => parseInt(user.UserId) === parseInt(userId))) {
      this.setState({
        SelectedUsers: this.state.SelectedUsers.filter(item => parseInt(item.UserId) !== parseInt(userId))
      });
    } else {
      this.setState({
        SelectedUsers: [...this.state.SelectedUsers, data],
      });
    }
  }


  addItem = async (id) => {
    await this.setState({ parentItem: id });
    this.editItem(0);

  }

  editItem = async (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({
        Id: data.Id,
        Name: data.Name,
        ParentId: data.ParentId,
        CompanyId: data.CompanyId,
        DateAdded: data.DateAdded,
        AddedBy: data.AddedBy,
        DateModified: data.DateModified,
        ModifiedBy: data.ModifiedBy,
        Status: data.Status,
        SelectedUsers: data.SelectedUsers
      });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Name: '',
        ParentId: this.state.parentItem,
        CompanyId: getUserDetails().CompanyId,
        DateAdded: new Date(),
        AddedBy: 0,
        DateModified: new Date(),
        ModifiedBy: 0,
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  generateNewJsonList = (userList, selectedUsers) => {
    return userList.map(user => {
      const isChecked = selectedUsers.some(selectedUser => selectedUser.UserId === user.Id);
      return {
        text: user.DisplayName,
        id: user.Id,
        isChecked: isChecked
      };
    });
  };

  generateViewList = (userList, selectedUsers) => {

    return userList.filter(user => selectedUsers.some(selectedUser => selectedUser.UserId === user.Id));
    //return userList.map(user => {
    //  if (selectedUsers.some(selectedUser => selectedUser.UserId === user.Id))
    //  return {
    //    text: user.DisplayName,
    //    id: user.Id,
    //  };
    //});
  };

  editUsers = async (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({
        Id: data.Id,
        SelectedUsers: data.SelectedUsers,
        //TeamUsers: this.generateNewJsonList(this.state.UserList, data.SelectedUsers)
        TeamUsers: this.generateViewList(this.state.UserList, data.SelectedUsers)
      });
    }
    else {
      //clear state fields
      this.setState({
        SelectedUsers: []
      });
    }
    this.setState({ editId: id, userModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.Name, 'tbNameError', '* required', 'tbName');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.Id === this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.setState({
          DateAdded: data.DateAdded,
          AddedBy: data.AddedBy,
          Id: data.Id,
          Status: data.Status
        });
      }
      else {
        this.setState({
          Status: 1
        });
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  saveTeam = async (event) => {
    event.stopPropagation();
    this.saveTeamUsers();
    this.setState({ userModal: false });

  }

  deleteItem = async (id) => {

    const result = await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles);
    if (result) {
      this.deleteData(id);
    }
  }


  hasChildren = (data, ParentId) => {
    return data.filter(item => item.ParentId === ParentId).length > 0;
  }

  nodeTemplate(data) {
    const addItem = () => { this.addItem(data.id) };
    const editData = () => { this.editItem(data.id) };
    const deleteItem = () => { this.deleteItem(data.id) };
    const editUsers = () => { this.editUsers(data.id) };
    //console.log(data);
    return (<div>
      <span className="me-2">{data.name}</span>

      <button type="button" className="btn-structures" onClick={addItem} title="Add sub-team"><i className="fa-solid fa-plus"></i></button>
      <button type="button" className="btn-structures" onClick={editData} title="Edit team"><i className="far fa-edit"></i></button>
      <button type="button" className="btn-structures position-relative" onClick={editUsers} title="Edit team members"><i className="fa-solid fa-users"></i>
        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
          {data.members}
          <span className="visually-hidden">Members</span>
        </span>
      </button>
      <button type="button" className="btn-structures" onClick={deleteItem} title="Delete team"><i className="far fa-trash-alt text-danger"></i></button>
    </div>);
  }

  selectTeamUsers = (UserId, IsSelected) => {
    //console.log(UserId, IsSelected);
    let selectedUsers = this.state.SelectedUsers;
    if (IsSelected) {
      selectedUsers.push({ UserId: UserId, TeamId: this.state.Id });
    } else {
      selectedUsers = selectedUsers.filter(item => item.UserId !== UserId);
    }
    //console.log(selectedUsers);
    this.setState({ SelectedUsers: selectedUsers });
    //const data = selectedUsers.find((item) => { return item.Id === UserId });
  }


  render() {

    if (this.state.loading)
      return (ShowLoader());

    let structures = [];

    this.state.editData.forEach((item) => {
      let child = this.hasChildren(this.state.editData, item.Id);
      structures.push({ id: item.Id, name: item.Name, pid: (item.ParentId > 0 ? item.ParentId : null), hasChild: child, expanded: child, members: item.SelectedUsers.length });

    });

    let fields = { dataSource: structures, id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild' };

    let parents = this.state.editData;
    if (this.state.editId) {
      parents = parents.filter(item => item.id !== this.state.editId)
    }
    //let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(this.state.editData, this.state.gridToolbar, this.onGridCommand);
    const teamMembers = this.state.SelectedUsers.length;
    return (
      <>
        <div className="container-fluid">
          <Row>
            <Col xs={4}>
              <h1>Teams</h1>
            </Col>
            <Col xs={8} className="text-end align-self-center">
              <Link to="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Master Data</Link>
              <Button color="primary" size="sm" data-recordid="0" onClick={e => this.addItem(0)}>Add Top Level Team <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <TreeViewComponent fields={fields} nodeTemplate={this.nodeTemplate} allowDragAndDrop={true} />
              {/*{contents}*/}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="md" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='100' floatLabelType='Always' showClearButton={true} value={this.state.Name} onChange={e => this.setState({ Name: e.target.value })} /> <div id='tbNameError' className='error-message' />
            </div>

            <div className='mb-3 d-none'>
              <DropDownListComponent id='ddParentId' name='ddParentId' placeholder='Select Parent (optional)' dataSource={parents} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.ParentId} change={e => this.setState({ ParentId: e.value })} /><div id='tbParentIdError' className='error-message' />
            </div>

            <div className='mb-3 d-none'>
              <small>Team Members</small>
              <button className="dd-btn" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                <span>{teamMembers > 0 ? `${teamMembers} ${teamMembers > 1 ? "Users" : "User"} Selected` : "Select Team Members"}</span>
                {this.state.isOpen ? <i className="fas fa-chevron-up text-end pe-1 text-muted"></i> : <i className="fas fa-chevron-down text-end pe-1 text-muted"></i>}
              </button>
              <div className='panel'>
                {this.state.isOpen && this.state.UserList.map((item, index) => (
                  <fieldset key={index}>
                    <input id={item.id} type="checkbox" onChange={e => this.handleChange(item.Id)} checked={this.state.SelectedUsers?.some(user => parseInt(user.UserId) === item.Id)} />
                    <label className="ps-2" htmlFor={item.Id}>{`${item.FirstName} ${item.LastName}`}</label>
                  </fieldset>
                ))}
              </div>
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>



        <Modal isOpen={this.state.userModal} toggle={this.toggleUsers} scrollable size="md" backdrop="static">
          <ModalHeader toggle={this.toggleUsers} close={<button className="close" onClick={this.toggleUsers}><i className="fas fa-times"></i></button>}>Team Members</ModalHeader>
          <ModalBody>
            
            {this.state.TeamUsers.length <= 0 && (
              <div>This team has no members</div>
            )}
            {this.state.TeamUsers?.length > 0 && (
              <ul className="list-group">
                {this.state.TeamUsers.map((item, index) => (
                  <li key={index} className="list-group-item">{item.DisplayName}</li>
                ))}
              </ul>
            )}

          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggleUsers}>Close <i className="far fa-times-circle ms-2"></i></Button>
            {/*<Button color="success" size="sm" onClick={this.saveTeam}>Save <i className="far fa-check-circle ms-2"></i></Button>*/}
          </ModalFooter>
        </Modal>


      </>

    );
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();


    try {
      const response = await fetch(`api/userdata/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        this.setState({ UserList: data, loading: false });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/teams/getcompanyteams/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data.map(item => ({ ...item, SelectedUsers: JSON.parse(item.SelectedUsers) }))
        //console.log(data);
        this.setState({ editData: data, loading: false });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    //setAuthToken(getAuthToken(), new Date());
  }

  async saveData(dataId) {
    const { handleNavigate } = this.context;
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Name: capitalizeFirstLetter(this.state.Name), ParentId: this.state.ParentId, CompanyId: getUserDetails().CompanyId, SelectedUsers: JSON.stringify(this.state.SelectedUsers), DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, }

    try {
      const response = await fetch('api/teams', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Teams', content: 'The team was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Teams', content: 'There was an error saving the team!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveTeamUsers() {
    var bearer = 'Bearer ' + getAuthToken();
    //var data = { SelectedUsers: this.state.SelectedUsers }

    try {
      const response = await fetch('api/teamuser/updateteam/' + this.state.Id, {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.SelectedUsers),
      });
      if (response.ok) {
        
        this.loadData();
        ToastUtility.show({
          title: 'Teams', content: 'The team was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        //if (response.status === 401)
        //  handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Teams', content: 'There was an error saving the team!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/teams/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Teams', content: 'The team was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Teams', content: 'There was an error deleting the team!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}