/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import Loader from '../Common/Loader';
import ActualRiskRating from './Actual/ActualRiskRating';
import AnalyseRisk from './Analyse/AnalyseRisk';
import RiskStatus from './Common/RiskStatus';
import RiskSummary from './Common/RiskSummary';
//import RiskValues from './Common/RiskValues';
import EvaluateRisk from './Evaluation/EvaluateRisk';
import PlanRisk from './Plan/PlanRisk';
import PotentialRiskRating from './Potential/PotentialRiskRating';
import TargetRiskRating from './Target/TargetRiskRating';
import TreatRisk from './Treat/TreatRisk';
import { LoadCachedAPIData, LoadAPIData } from '../../helpers/DataHelper';

export default function RiskEditor(props) {

  const [riskData, setRiskData] = useState();
  const [siteData, setSiteData] = useState();
  const [companyData, setCompanyData] = useState();

  const [riskAnalysisStatusData, setRiskAnalysisStatusData] = useState();
  const [riskEvaluateStatusData, setRiskEvaluateStatusData] = useState();
  const [riskTreatStatusData, setRiskTreatStatusData] = useState();
  const [riskPlanStatusData, setRiskPlanStatusData] = useState();
  const [riskEvaluationData, setRiskEvaluationData] = useState();
  const [riskStep, setRiskStep] = useState();
  const [userList, setUserList] = useState();

  const [riskStatus, setRiskStatus] = useState();
  const [rootCauses, setRootCauses] = useState();
  const [controls, setControls] = useState();
  const [consequences, setConsequences] = useState();
  const [potentialActualTargetData, setPotentialActualTargetData] = useState();

  const [riskId, setRiskId] = useState('');
  const [isLoading, setIsLoading] = useState(true);


  const loadRisk = async (loadAssociatedData) => {
    setIsLoading(true);
    var bearer = 'Bearer ' + getAuthToken();
    const parts = window.location.href.split("/");
    const riskNumber = parts[parts.length - 1];
    setRiskId(riskNumber);
    LoadCachedAPIData(`api/riskstatuses/listallbycompany/${getUserDetails().CompanyId}/10`);

    //const userData = getUserDetails();
    try {
      const response = await fetch(`api/risks/getbyriskid/${riskNumber}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log("risk data: ", data);
        await setRiskData(data);
        if (loadAssociatedData)
          loadRiskData(data);
        else
          setIsLoading(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const loadRiskData = async (riskData) => {
    const userData = getUserDetails();
    setSiteData(await LoadAPIData(`api/sitestructures/${riskData.SiteStructureId}`));

    //Load Company Data
    setCompanyData(await LoadAPIData(`api/companies/${userData.CompanyId}`));

    //Load Risk Evaluations
    const data = await LoadAPIData(`api/riskevaluation/GetRiskEvaluations/${userData.CompanyId}/${riskData.Id}/${userData.UserRole}`)
    setPotentialActualTargetData(data);

    //Load Risk Evaluations
    setRiskEvaluationData(data);

    //Load Risk Status
    setRiskStatus(await LoadCachedAPIData(`api/riskstatuses/listallbycompany/${userData.CompanyId}/${userData.UserRole}`));

    //Load Company Users
    const coUsers = await LoadAPIData(`api/userdata/getbycompany/${userData.CompanyId}/${userData.UserRole}`);
    const users = coUsers.map(item => ({
      ...item,
      FullName: item.FirstName + " " + item.LastName
    }));
    setUserList([{ Id: 0, DisplayName: '- Select User -' }, ...users]);

    loadRiskStageStatus(riskData);
    loadRiskDecomposition(riskData);
    setIsLoading(false);
  }

  const loadRiskStageStatus = async (riskData) => {
    const userData = getUserDetails();

    //Load Risk Analysis Status
    setRiskAnalysisStatusData(await LoadCachedAPIData(`api/riskanalysisstatus/getbyriskid/${riskData.Id}/${userData.UserRole}`));

    //Load Risk Evaluate Status
    setRiskEvaluateStatusData(await LoadCachedAPIData(`api/riskevaluatestatus/getbyriskid/${riskData.Id}/${userData.UserRole}`));

    //Load Risk Treat status
    setRiskTreatStatusData(await LoadCachedAPIData(`api/risktreatstatus/getbyriskid/${riskData.Id}/${userData.UserRole}`));

    //Load Risk Plan status
    setRiskPlanStatusData(await LoadCachedAPIData(`api/riskplanstatus/getbyriskid/${riskData.Id}/${userData.UserRole}`));
    return true;
  }

  const loadRiskDecomposition = async (riskData) => {
    const userData = getUserDetails();
    //Load root causes
    setRootCauses(await LoadCachedAPIData(`api/rootcauses/getbyriskid/${riskData.Id}/${userData.UserRole}`));
    //console.log("After Root Causes", riskData.Id, isLoading);
    //Load Controls
    const controlData = await LoadAPIData(`api/controls/getbyriskid/${riskData.Id}/${userData.UserRole}`);
    setControls(await controlData.map(item => ({ ...item, Assurances: JSON.parse(item.Assurances), ConnectedRootCauses: item.ConnectedRootCauses ? JSON.parse(item.ConnectedRootCauses) : [], ConnectedConsequences: item.ConnectedConsequences ? JSON.parse(item.ConnectedConsequences) : [] })));
    //console.log("After Controls", riskData.Id, isLoading);
    //Load Consequences
    const consequenceData = await LoadAPIData(`api/consequences/getbyriskid/${riskData.Id}/${userData.UserRole}`);
    setConsequences(await consequenceData.map(item => ({ ...item, ConnectedPerspectives: item.ConnectedPerspectives ? JSON.parse(item.ConnectedPerspectives) : [] })));
    //console.log("After Consequences", riskData.Id, isLoading);

    return true;
  }

  useEffect(() => {
    //console.clear();
    loadRisk(true);
  }, []);


  const eventHandler = (data) => {
    setRiskStep(data);
    //console.log(data);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 "><h1>Risk Engagement</h1></div>
        <div className="col-md-6 text-end align-self-center">
          <Link to="/my-items" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Risks</Link>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <RiskSummary RiskData={riskData} CompanyData={companyData} EventHandler={eventHandler} RiskEvaluations={riskEvaluationData} />
            {/* <RiskValues RiskData={riskData} SiteData={siteData} CompanyData={companyData} EventHandler={eventHandler} RiskEvaluations={riskEvaluationData} /> */}
          <RiskStatus RiskData={riskData} RiskAnalysisStatusData={riskAnalysisStatusData} RiskEvaluateStatusData={riskEvaluateStatusData} RiskTreatStatusData={riskTreatStatusData} RiskPlanStatusData={riskPlanStatusData} EventHandler={eventHandler} />

          <PotentialRiskRating RiskData={riskData} SiteData={siteData} ControlsData={controls} RiskEvaluateStatusData={riskEvaluateStatusData} RootCauseData={rootCauses} ConsequenceData={consequences} PotentialData={potentialActualTargetData.PotentialData} RiskImpactRatings={potentialActualTargetData.RiskImpactRatings} IsVisible={riskStep === "potential"} OnStatusUpdate={() => loadRiskData(riskData)} />
          <ActualRiskRating RiskData={riskData} SiteData={siteData} ControlsData={controls} RiskEvaluateStatusData={riskEvaluateStatusData} RootCauseData={rootCauses} ConsequenceData={consequences} ActualData={potentialActualTargetData.ActualData} RiskImpactRatings={potentialActualTargetData.RiskImpactRatings} IsVisible={riskStep === "actual"} OnStatusUpdate={() => loadRiskData(riskData)} />
          <TargetRiskRating RiskData={riskData} SiteData={siteData} ControlsData={controls} RiskEvaluateStatusData={riskEvaluateStatusData} RootCauseData={rootCauses} ConsequenceData={consequences} TargetData={potentialActualTargetData.TargetData} RiskImpactRatings={potentialActualTargetData.RiskImpactRatings} IsVisible={riskStep === "target"} OnStatusUpdate={() => loadRiskData(riskData)} />

          <AnalyseRisk RiskId={riskData.Id} RiskData={riskData} IsVisible={riskStep === "analyse"} RootCauseData={rootCauses} ConsequenceData={consequences} ControlsData={controls} SiteData={siteData} UserList={userList} EventHandler={() => loadRisk(false)} OnDecompositionReload={() => loadRiskDecomposition(riskData)} OnStatusUpdate={() => loadRiskStageStatus(riskData)} />
          <EvaluateRisk RiskId={riskData.Id} CompanyData={companyData} IsVisible={riskStep === "evaluate"} RiskData={riskData} SiteData={siteData} RiskEvaluations={riskEvaluationData} EventHandler={eventHandler} OnStatusUpdate={() => loadRiskStageStatus(riskData)} />
          <TreatRisk RiskId={riskData.Id} UserList={userList} IsVisible={riskStep === "treat"} OnStatusUpdate={() => loadRiskStageStatus(riskData)} />
          <PlanRisk RiskId={riskData.Id} IsVisible={riskStep === "plan"} UserList={userList} ControlsData={controls} OnStatusUpdate={() => loadRiskStageStatus(riskData)} OnReloadControls={() => { loadRiskDecomposition(riskData) }} />

        </>
      )}

    </>
  );
}

