import { DropDownListComponent, DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { CommandTemplate, ConfirmDialogStyles } from '../../helpers/global';
import { fieldRequired } from '../../helpers/validation';

export class UserData extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    const userTypeData = [
      { value: 0, text: '- Select -' },
      { value: 1, text: 'Company User' },
      { value: 10, text: 'Ops Admin' },
      { value: 11, text: 'Config Admin' },
      { value: 100, text: 'Super Admin' },
    ];

    this.state = {
      editData: [], loading: true, editModal: false, gridToolbar: ['Search'],
      Id: 0,
      CompanyId: 0,
      UserId: '',
      UserRole: 0,
      Email: '',
      EmailConfirmed: false,
      FirstName: '',
      LastName: '',
      Mobile: '',
      Tel: '',
      CurrentPassword: '',
      LastPassword: '',
      DateRegistered: new Date(),
      DateVerified: new Date(),
      LastLogin: new Date(),
      FailedLogins: 0,
      LockedOut: false,
      LockOutDate: new Date(),
      LastIP: '',
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      userTypes: userTypeData,
      CompanyList: [],
      LoginCookie: '',
      TeamData: [],
    };
    this.toggle = this.toggle.bind(this);
    //this.renderRole = this.renderRole.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "User Administration :: OnPoint RMS";
    this.loadData();
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  addItem = (ev) => { this.editItem(0); }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({ Id: data.Id, CompanyId: data.CompanyId, UserId: data.UserId, UserRole: data.UserRole, Email: data.Email, EmailConfirmed: data.EmailConfirmed, FirstName: data.FirstName, LastName: data.LastName, Mobile: data.Mobile, Tel: data.Tel, CurrentPassword: data.CurrentPassword, TeamId: data.TeamId, TeamName: data.TeamName, TeamUserId: data.TeamUserId  });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        CompanyId: 0,
        UserId: '',
        UserRole: 0,
        Email: '',
        EmailConfirmed: false,
        FirstName: '',
        LastName: '',
        Mobile: '',
        Tel: '',
        TeamId: 0,
        TeamUserId: 0,
        TeamName: '',
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    //valid &= fieldRequired(this.state.CompanyId, 'tbCompanyIdError', '* required', 'tbCompanyId');
    valid &= fieldRequired(this.state.UserRole, 'tbUserRoleError', '* required', 'tbUserRole');
    valid &= fieldRequired(this.state.Email, 'tbEmailError', '* required', 'tbEmail');
    valid &= fieldRequired(this.state.FirstName, 'tbFirstNameError', '* required', 'tbFirstName');
    valid &= fieldRequired(this.state.LastName, 'tbLastNameError', '* required', 'tbLastName');
    valid &= fieldRequired(this.state.TeamId, 'tbUserTeamError', '* required', 'ddUserTeam');
    //valid &= fieldRequired(this.state.CurrentPassword, 'tbCurrentPasswordError', '* required', 'tbCurrentPassword');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.Id === this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.setState({
          DateAdded: data.DateAdded,
          AddedBy: data.AddedBy,
          Id: data.Id,
          Status: data.Status
        });

      }
      else {
        this.setState({
          Status: 1
        });

      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {

    const result = await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.Id);
        break;
      default: //edit
        this.deleteItem(args.rowData.Id);
        break;
    }
  }

  GenerateTeamStructureTree = (teamData) => {
    let result = [];
    
      result.push({ id: 0, name: "- Select -", pid: null, hasChild: false, selected: true, expanded: true, });

    teamData.forEach((item) => {
      result.push({ id: item.Id, name: item.Name, pid: (item.ParentId > 0 ? item.ParentId : null), hasChild: item.HasChildren });

    });
    return result;
  }
    
  RenderRole = (props) => {
    
    const type = this.state.userTypes.find(o => o.value === props.UserRole);
    return type?.text;
  }

  renderDataTable(data, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='FirstName' width='60' headerText="First Name" />
          <ColumnDirective field='LastName' width='60' headerText="Last Name" />
          <ColumnDirective field='CompanyName' width='100' headerText="Company Name" />
          <ColumnDirective field='TeamName' width='80' headerText="Team" />
          <ColumnDirective field='UserRole' width='40' headerText="User Role" template={this.RenderRole} />
          <ColumnDirective headerText='Actions' width='40' commands={CommandTemplate} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(this.state.editData, this.state.gridToolbar, this.onGridCommand);
    let teamsFilter = { dataSource: this.GenerateTeamStructureTree(this.state.TeamData), value: 'id', text: 'name', parentValue: "pid", hasChildren: 'hasChild' };
    console.log(teamsFilter);
    return (
      <>
        <div className="container-fluid">
          <Row>
            <Col xs={5}>
              <h1>Users</h1>
            </Col>
            <Col xs={7} className="text-end align-self-center">
              <Link to="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Master Data</Link>
              <Button color="primary" size="sm" data-recordid="0" onClick={e => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit User</ModalHeader>
          <ModalBody>
            <div className="row">

              <div className='col-md-6 mb-3'>
                <DropDownListComponent id='tbUserRole' name='tbUserRole' placeholder='User Role' dataSource={this.state.userTypes} fields={{ text: 'text', value: 'value' }} floatLabelType='Always' value={this.state.UserRole} change={e => this.setState({ UserRole: e.itemData.value })} /><div id='tbUserRoleError' className='error-message' />
              </div>

              <div className='col-md-6 mb-3'>
                {/*<DropDownListComponent id='tbUserTeam' name='tbUserTeam' placeholder='Team' dataSource={this.state.userTypes} fields={{ text: 'text', value: 'value' }} floatLabelType='Always' value={this.state.UserRole} change={e => this.setState({ UserRole: e.itemData.value })} /><div id='tbUserTeamError' className='error-message' />*/}
                <DropDownTreeComponent fields={teamsFilter} id='ddUserTeam' name='ddUserTeam' showClearButton={false} placeholder='Team' floatLabelType='Always' value={[this.state.TeamId]} select={e => this.setState({ TeamId: e.itemData.id })} />
                {/*select={e => this.setState({ fSiteStructureId: e.itemData.id })} value={[this.state.fSiteStructureId]}*/}
                <div id='tbUserTeamError' className='error-message' />
              </div>
            </div>
            <div className="row">

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbFirstName' name='tbFirstName' placeholder='First Name' type='text' maxLength='350' floatLabelType='Always' showClearButton={true} value={this.state.FirstName} onChange={e => this.setState({ FirstName: e.target.value })} /> <div id='tbFirstNameError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbLastName' name='tbLastName' placeholder='Last Name' type='text' maxLength='350' floatLabelType='Always' showClearButton={true} value={this.state.LastName} onChange={e => this.setState({ LastName: e.target.value })} /> <div id='tbLastNameError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbMobile' name='tbMobile' placeholder='Mobile' type='text' maxLength='50' floatLabelType='Always' showClearButton={true} value={this.state.Mobile} onChange={e => this.setState({ Mobile: e.target.value })} /> <div id='tbMobileError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbTel' name='tbTel' placeholder='Tel' type='text' maxLength='50' floatLabelType='Always' showClearButton={true} value={this.state.Tel} onChange={e => this.setState({ Tel: e.target.value })} /> <div id='tbTelError' className='error-message' />
              </div>
              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbEmail' name='tbEmail' placeholder='Email' type='text' maxLength='350' floatLabelType='Always' showClearButton={true} value={this.state.Email} onChange={e => this.setState({ Email: e.target.value })} /> <div id='tbEmailError' className='error-message' />
              </div>


              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbCurrentPassword' name='tbCurrentPassword' placeholder='Set Password' type='password' maxLength='350' floatLabelType='Always' showClearButton={true} value={this.state.CurrentPassword} onChange={e => this.setState({ CurrentPassword: e.target.value })} /> <div id='tbCurrentPasswordError' className='error-message' />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    //let path = userData.UserRole === 100 ? "api/userdata" : `api/userdata/getbycompany/${userData.CompanyId}/${userData.UserRole}`;
    let path = `api/userdata/getbycompany/${userData.CompanyId}/${userData.UserRole}`;
    try {
      const response = await fetch(path, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/teams/getcompanyteams/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        this.setState({ TeamData: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
            handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    //setAuthToken(getAuthToken(), new Date());
  }

  async saveData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = {
      Id: this.state.Id, CompanyId: getUserDetails().CompanyId, UserId: this.state.UserId, UserRole: this.state.UserRole, Email: this.state.Email.toLocaleLowerCase(), EmailConfirmed: true,
      FirstName: this.capitalizeFirstLetter(this.state.FirstName), LastName: this.capitalizeFirstLetter(this.state.LastName), Mobile: this.state.Mobile, Tel: this.state.Tel,
      CurrentPassword: this.state.CurrentPassword,
      TeamId: this.state.TeamId,
      TeamUserId: this.state.TeamUserId,
      //AddedBy: this.state.AddedBy,
    }

    try {
      const response = await fetch('api/userdata', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Users', content: 'The user was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
        if (response.status === 409) {
          ToastUtility.show({
            title: 'Users', content: 'A user with that email address already exists!', timeOut: 8000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
          });
          this.setState({ loading: false });
        }
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Users', content: 'There was an error saving the user!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/userdata/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Users', content: 'The user was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Users', content: 'There was an error deleting the user!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

