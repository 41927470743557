/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { getUserDetails } from '../../helpers/authentication';
import RiskProperties from '../Risk/Common/RiskProperties';

export default function Home(props) {

  const risksRef = useRef();

  const addNewRisk = (event) => {
    event.preventDefault();
    
    if (risksRef.current) {
      risksRef.current.ShowEditor(0); // Call ShowEditor with a RiskId
    }
  };


  return (
    <>
      <div className="row home-styles">
        <div className="col mb-3 ">
          <button className="card w-100" onClick={addNewRisk}>
            
            <div className="card-body text-center">
              <i className="fa-solid fa-plus fa-4x"></i><br /><br />
              Add Risk
            </div>
          </button>
        </div>
        <div className="col mb-3">
          <Link to="/processing" className="card">
            {/*<a href="my-items" className="card">*/}
            <div className="card-body text-center">
              <i className="fa-solid fa-pen-to-square fa-4x"></i><br /><br />
              Processing
            </div>
          </Link>
        </div>

        {getUserDetails().UserRole > 9 ? <div className="col mb-3">
          <Link to="/admin" className="card">
            <div className="card-body text-center">
              <i className="fa-solid fa-database fa-4x"></i><br /><br />
              Master Data
            </div>
          </Link>
        </div> : ""}
      </div>


      <p className="text-center">Show due and upcoming tasks for the current user</p>

      <RiskProperties ref={risksRef} />
    </>
  );
}
